import { FC, useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import { CssBaseline, ThemeProvider, StyledEngineProvider, Box, CircularProgress } from '@mui/material';
import './i18n';
import useScrollReset from './hooks/useScrollReset';
import routes from './routes';
import { createCustomTheme } from './theme';
import axios, { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { useAppStore } from 'src/store/mobx/appStore';
import { observer } from 'mobx-react';
import useMounted from 'src/hooks/useMounted';
import loadHotjarAnalytics from './utils/hotjarUtil';
import CustomerSelection from './components/dashboard/customerSelection/CustomerSelection';
import { resolveTheme } from './utils/themeUtils';
import { getAuthHeader } from './utils/requestUtils';
import './fonts.css';
import authenticationModule from './azure/azure-authentication-context';

const App: FC = observer(() => {
  const content = useRoutes(routes);
  const { t } = useTranslation();
  const appStore = useAppStore();
  const loginStore = appStore.loginStore.get();
  const { user, refreshingAuthentication } = loginStore;
  const settings = appStore.settingsStore.get();
  const mounted = useMounted();

  useEffect(() => {
    if (mounted.current) {
      loadHotjarAnalytics();
      // just in case
      // appStore.loginStore.setIsRefreshingAuthentication(false);
    }
  }, [mounted]);

  useEffect(() => {
    const refreshUnreadMessageThreads = () => {
      appStore.notificationStore.refreshUnreadMessagesNotifications(user);
    };

    const interval = setInterval(() => {
      if (!document.hidden) {
        refreshUnreadMessageThreads();
      }
    }, 1000 * 120);


    return () => {
      clearInterval(interval);
    };
  }, [user]);

  useEffect(() => {
    // Add a response interceptor
    const interceptor = axios.interceptors.response.use((response) => response,
      async (error: AxiosError) => {
        // If we get a 401 response, the token might be invalidated.
        if (error?.response?.status === 401) {
          console.warn('Token has expired and should get refreshed');
          try {
            const token = await authenticationModule.getToken();
            const uninterceptedAxiosInstance = axios.create();
            return await new Promise(async (resolve, reject) => {
              try {
                const res = await uninterceptedAxiosInstance.request({ ...error.config, headers: { ...error.config?.headers, Authorization: getAuthHeader(token) } });
                return resolve(res);
              } catch (uninterceptedError) {
                return reject(uninterceptedError);
              }
            });
          } catch (refreshErr) {
            toast.error(t('SessionExpiredToast'), {
              id: 'sessionExpired',
            });
            appStore.loginStore.logout();
          }
        }
        return Promise.reject(error);
      });

    return () => {
      axios.interceptors.response.eject(interceptor);
    };

  }, []);

  useScrollReset();

  const theme = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme ?? resolveTheme()
  });

  useEffect(() => {
    i18n.changeLanguage(settings.language);
  }, [settings]);

  const loadingSpinner = (
    <Box
      display="flex"
      justifyContent="center"
      sx={{ mt: 10 }}
      bgcolor="background.secondary"
    >
      <CircularProgress
        size={65}
      />
    </Box>
  );

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Toaster position="top-center" />
        <CustomerSelection displayDialog={user?.customerId === undefined} />
        {refreshingAuthentication ? loadingSpinner : content}
      </ThemeProvider>
    </StyledEngineProvider>
  );
});

export default App;
