// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { action, makeAutoObservable, } from 'mobx';
import { StorageKeys } from 'src/constants';
import { SiteUser } from 'src/types/siteUser';
import { autoSaveToStorage } from './autoSaveToStorage';
import { AuthConfigStorageService, IAuthConfigStorageService } from 'src/Services';

export interface LoginEntity {
  isAuthenticated: boolean;
  user: SiteUser | null;
  refreshingAuthentication: boolean;
  isAuthenticating?: boolean;
}

export class LoginStore {
  private static instance = null;

  constructor() {
    makeAutoObservable(this);
    const authConfigStorageService: IAuthConfigStorageService = new AuthConfigStorageService();
    const storage = authConfigStorageService.authStorage;
    // storage location depending on selection for login information
    autoSaveToStorage(this, StorageKeys.loginStoreKey, storage);
  }

  public static getInstance(): LoginStore {
    if (LoginStore.instance === null) {
      LoginStore.instance = new LoginStore();
    }
    return LoginStore.instance;
  }

  private loginState: LoginEntity = { user: null, isAuthenticated: false, refreshingAuthentication: false, isAuthenticating: false };

  /** reset logint store instance: used when toggling between login related persistence on login page: sessionStorage or localStorage */
  @action.bound
  public reset(): void {
    LoginStore.instance = new LoginStore();
    this.set({ user: null, isAuthenticated: false, refreshingAuthentication: false, isAuthenticating: false });
  }

  @action.bound
  private set(value: LoginEntity): void {
    this.loginState = value;
  }

  @action.bound
  public get(): LoginEntity {
    return this.loginState;
  }

  @action.bound
  public beginLogin() {
    this.set({ ...this.loginState, isAuthenticating: true });
  }

  @action.bound
  public login(user: SiteUser, refreshingAuthentication?: boolean) {
    this.set({ user, isAuthenticated: true, refreshingAuthentication: refreshingAuthentication ?? false, isAuthenticating: false });
  }

  @action.bound
  public logout() {
    this.set({ user: null, isAuthenticated: false, refreshingAuthentication: false, isAuthenticating: false });
  }

  @action.bound
  public updateAvatar(avatar: string) {
    if (this.loginState.user) {
      this.loginState.user.avatar = avatar;
    }
  }

  @action.bound
  public setIsRefreshingAuthentication(refreshingToken: boolean) {
    this.set({ ...this.loginState, refreshingAuthentication: refreshingToken });
  }
}
