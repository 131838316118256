import { useEffect } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { LoginInfoDto } from 'src/types/apiSchemas';
import { baseApi } from 'src/API/baseApi';
import { useAppStore } from 'src/store/mobx/appStore';
import { observer } from 'mobx-react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import authenticationModule from '../azure/azure-authentication-context';
import { ILoginInfoUpdate, LoginInfo, RefreshOperation } from 'src/utils/LoginInfoUtil';

interface AuthProviderProps {
  children: ReactNode;
}

const AuthProvider: FC<AuthProviderProps> = observer((props) => {
  const { children } = props;
  const appStore = useAppStore();
  const { user } = appStore.loginStore.get();
  const { t } = useTranslation();
  const loginInfo = new LoginInfo(appStore);

  const tryGetLoginInfo = async (accessToken: string): Promise<LoginInfoDto> => {
    let currentUserLoginInfo: LoginInfoDto = null;
    try {
      currentUserLoginInfo = await baseApi.login(accessToken);
    } catch (err) {
      console.error(`tryGetLoginInfo:: Failed to load loginInfo, ${err}`);
    }
    return currentUserLoginInfo;
  };

  useEffect(() => {
    const init = async (): Promise<void> => {
      try {
        if (user) {
          const accessToken = await authenticationModule.getToken();
          const info = await tryGetLoginInfo(accessToken);
          // refreshing the resolved data to user related objects (login, settings) in store
          loginInfo.updateUser({ userDto: info, operation: RefreshOperation.update } as ILoginInfoUpdate);
        } else {
          appStore.loginStore.logout();
        }
      } catch (err) {
        appStore.loginStore.logout();
        toast.error(t('SessionExpiredToast'), {
          id: 'sessionExpired',
        });
      }
    };

    init();
  }, []);

  return (
    <>{children}</>
  );
});

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthProvider;
