import { Grid, Typography, TextField, Button } from '@mui/material';
import toast from 'react-hot-toast';
import stateStore, { MessageView } from 'src/store/mobx/stateStore';
import CommonModal from './common/CommonModal';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { MessageThreadSort, SortDirection } from 'src/types/apiSchemas';
import { useAppStore } from 'src/store/mobx';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router';

const CreateMessageViewModal = observer(({ onCancel, onSave }: { onCancel: () => void, onSave: () => void }) => {
  const appStore = useAppStore();
  const { user } = appStore.loginStore.get();
  const { messageViews = [], createMessageView } = stateStore;
  const [filterSetName, setFilterSetName] = useState('');
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const value = e.target.value;
    const cleanedValue = value.replace(/[^a-zA-Z0-9åäöÅÄÖ -]/g, '');
    setFilterSetName(cleanedValue);
  };

  return (
    < CommonModal
      open={true}
    >
      <Grid container justifyContent="space-between" gap={2}>
        <Grid item xs={12}><Typography variant="h5">{t('MessagesPageSaveFilters')}</Typography></Grid>
        <Grid item xs={12}>
          <TextField value={filterSetName}
            onChange={(e) => {
              handleChange(e);
            }}
            sx={{ width: 300 }}
            autoComplete="off"
            label={t('MessagesPageFilterViewName')}
          />
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained"
            disabled={filterSetName.trim().length < 1}
            sx={{ marginRight: 2 }}
            onClick={() => {
              const urlName = filterSetName.trim().replaceAll(' ', '-').replaceAll('Ä', 'a').replaceAll('ä', 'a').replaceAll('Ö', 'o').replaceAll('ö', 'o').replaceAll('Å', 'a').replaceAll('å', 'a').toLowerCase().replace(/[^a-z0-9\-]/g, '');

              if (messageViews.some(f => f.customerId === user.customerId && f.urlName === urlName)) {
                toast.error(t('MessagesPageViewAlreadyExists'));
              } else {
                const mv: MessageView = {
                  customerId: user.customerId,
                  name: filterSetName,
                  messagesSearch: '',
                  messagesAssignee: [],
                  messagesCustomerAssignee: [],
                  messagesCategory: [],
                  messagesCategoryType: [],
                  messagesStatus: [],
                  messagesCustomer: [],
                  messagesSLA: [],
                  messagesRelatedCompany: [],
                  messagesSort: MessageThreadSort.LastMessage,
                  messagesSortDirection: SortDirection.Desc,
                  urlName
                };
                createMessageView(mv);
                setFilterSetName('');
                toast.success(t('SavingSucceeded'));
                navigate(`/dashboard/messages/views/${urlName}`);
                onSave();
              }
            }}
          >
            {t('GenericButtonSave')}
          </Button>
          <Button variant="light"
            onClick={onCancel}
          >
            {t('ButtonCancel')}
          </Button>
        </Grid>
      </Grid>
    </CommonModal>);
});

export default CreateMessageViewModal;