import { Avatar } from '@mui/material';
import { getInitials } from 'src/utils/getInitial';

const UserDisplay = ({ displayName, imageUrl, secondaryText }: { displayName: string, imageUrl?: string, secondaryText?: string }) => (
  <div style={{ display: 'flex', alignItems: 'center', gap: 15 }}>
    <div>
      <Avatar
        sx={{
          backgroundColor: 'success.main',
          color: '#ffffff'
        }}
        src={imageUrl}
        alt={displayName}
      >
        {getInitials(displayName)}
      </Avatar>
    </div>
    <div>
      <div>
        {displayName}
      </div>
      {secondaryText && <div style={{ opacity: 0.5, fontSize: '0.8rem' }}>{secondaryText}</div>}
    </div>
  </div>
);

export default UserDisplay;
