import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { $generateHtmlFromNodes } from '@lexical/html';
import { AutoLinkPlugin } from '@lexical/react/LexicalAutoLinkPlugin';
import { useEffect, useState } from 'react';
import { $createParagraphNode, $createTextNode, $getRoot } from 'lexical';
import { MessageAttachmentCreation, MessageThreadVisibility } from 'src/types/apiSchemas';
import ToolbarPlugin from './ToolbarPlugin';
import MentionsPlugin from './MentionsPlugin';
import ImagesPlugin from './ImagesPlugin';
import './MessageEditor.css';
import { editorConfig } from './editorConfig';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { Mention } from './MentionNode';


const URL_MATCHER =
  /((https?:\/\/(www\.)?)|(www\.))[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

const MATCHERS = [
  (text) => {
    const match = URL_MATCHER.exec(text);
    if (match === null) {
      return null;
    }
    const fullMatch = match[0];
    return {
      index: match.index,
      length: fullMatch.length,
      text: fullMatch,
      url: fullMatch.startsWith('http') ? fullMatch : `https://${fullMatch}`,
      attributes: { target: '_blank' },
    };
  },
];

function Editor({
  height,
  maxHeight,
  showToolbar = false,
  defaultText = '',
  onChange,
  messageSent,
  onAttachmentsAdd,
  onAttachmentsRemove,
  onMentionAdd,
  onMentionRemove,
  messageThreadUniqueId,
  messageThreadVisibility,
  customerId
}: {
  height?: number,
  maxHeight?: number,
  showToolbar?: boolean,
  defaultText?: string,
  onAttachmentsAdd: (attachments: MessageAttachmentCreation[]) => void,
  onAttachmentsRemove: (srcUrl: string) => void,
  onChange?: ({ richTextContent, plainTextContent }: { richTextContent: string, plainTextContent: string }) => void,
  onMentionAdd: (mention: Mention) => void,
  onMentionRemove: (mention: Mention) => void,
  messageSent: boolean,
  messageThreadUniqueId: string,
  messageThreadVisibility: MessageThreadVisibility,
  customerId: number
}) {
  const [lexicalEditor] = useLexicalComposerContext();
  const [richTextContent, setRichTextContent] = useState('');
  const [plainTextContent, setPlainTextContent] = useState('');
  const [toolbarVisibile, setToolbarVisible] = useState(showToolbar);

  useEffect(() => {
    lexicalEditor.setEditable(false);
    lexicalEditor.update(() => {
      $getRoot().clear();
      const paragraph = $createParagraphNode();
      $getRoot().append(paragraph);
    });
    requestAnimationFrame(() => lexicalEditor.setEditable(true));
  }, [messageSent]);

  useEffect(() => {
    if (onChange) {
      onChange({ richTextContent, plainTextContent });
    }
  }, [richTextContent, plainTextContent]);

  useEffect(() => {
    lexicalEditor.setEditable(false);
    lexicalEditor.update(() => {
      $getRoot().clear();

      const paragraph = $createParagraphNode();
      paragraph.append($createTextNode(defaultText));
      $getRoot().append(paragraph);
    });
    requestAnimationFrame(() => {
      lexicalEditor.setEditable(true);
      setRichTextContent(defaultText);
      setPlainTextContent(defaultText);
    });
  }, [defaultText]);

  return (
    <div className="editor-container">
      <div style={{ position: 'relative' }}>
        <ToolbarPlugin
          onAttachmentAdd={(attachment) => {
            onAttachmentsAdd([attachment]);
          }}
          visible={toolbarVisibile}
        />
      </div>
      <div className="editor-inner" style={{ ...(height ? { height } : {}), ...(maxHeight ? { maxHeight, overflow: 'auto' } : {}) }}>
        <RichTextPlugin
          contentEditable={
            <ContentEditable
              className="editor-input"
              onFocus={() => {
                setToolbarVisible(true);
              }}
              style={{ ...(height ? { minHeight: height } : {}) }}
            />
          }
          ErrorBoundary={LexicalErrorBoundary}
        />
        <ListPlugin />
        <ImagesPlugin captionsEnabled={false}
          onImageDelete={(image) => {
            onAttachmentsRemove(image.__src);
          }}
        />
        <OnChangePlugin onChange={(editorState, editor) => {
          editor.update(() => {
            const richText = $generateHtmlFromNodes(editor, null);
            const stringifiedEditorState = JSON.stringify(
              editor.getEditorState().toJSON(),
            );
            const parsedEditorState = editor.parseEditorState(stringifiedEditorState);
            const plainText = parsedEditorState.read(() => $getRoot().getTextContent());
            setRichTextContent(richText);
            setPlainTextContent(plainText);
          });
        }}
        />
        <AutoLinkPlugin matchers={MATCHERS} />
        <MentionsPlugin messageThreadVisibility={messageThreadVisibility} onMentionAdd={onMentionAdd} onMentionRemove={onMentionRemove} customerId={customerId} messageThreadUniqueId={messageThreadUniqueId} />
      </div>
    </div >);
}

export default function MessageEditor({
  height,
  maxHeight,
  showToolbar = false,
  defaultText = '',
  onChange,
  messageSent,
  onAttachmentsAdd,
  onAttachmentsRemove,
  onMentionAdd,
  onMentionRemove,
  messageThreadUniqueId,
  messageThreadVisibility,
  customerId
}: {
  height?: number,
  maxHeight?: number,
  showToolbar?: boolean,
  defaultText?: string,
  onChange?: ({ richTextContent, plainTextContent }: { richTextContent: string, plainTextContent: string }) => void,
  onAttachmentsAdd: (attachments: MessageAttachmentCreation[]) => void,
  onAttachmentsRemove: (srcUrl: string) => void,
  onMentionAdd: (mention: Mention) => void,
  onMentionRemove: (mention: Mention) => void,
  messageSent: boolean,
  messageThreadUniqueId?: string,
  messageThreadVisibility?: MessageThreadVisibility,
  customerId: number
}) {
  return (
    <LexicalComposer initialConfig={editorConfig}>
      <Editor
        maxHeight={maxHeight}
        height={height}
        showToolbar={showToolbar}
        defaultText={defaultText}
        onChange={onChange}
        messageSent={messageSent}
        onAttachmentsAdd={onAttachmentsAdd}
        onAttachmentsRemove={onAttachmentsRemove}
        onMentionAdd={onMentionAdd}
        onMentionRemove={onMentionRemove}
        messageThreadUniqueId={messageThreadUniqueId}
        messageThreadVisibility={messageThreadVisibility}
        customerId={customerId}
      />
    </LexicalComposer>
  );
}
