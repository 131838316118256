import type { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Card,
  CardContent,
  Container,
  Typography
} from '@mui/material';
import MSALLogin from '../../components/authentication/login/MSALLogin';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { resolveTheme } from 'src/utils/themeUtils';
import { THEMES } from 'src/constants';
import Scrollbar from 'src/components/Scrollbar';
import LanguageSelect from '../../components/common/LanguageSelect';

const Login: FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('md'));

  const images = {
    Microsoft: '/static/icons/microsoft.svg',
    IntegrataLogoWhite: '/static/integrata_logo_static_white.png',
    HumHumLogoWhite: '/static/humhum_logo_static_white.png',
    Logo: theme.loginPage.logo
  };

  const activeTheme = resolveTheme();

  return (
    <>
      <Helmet>
        <title>{t('LoginPageHelmet')}</title>
      </Helmet>
      <Box
        sx={{
          minHeight: '100vh',
          backgroundColor: '#2d3436',
          background: theme.loginPage.background,
          backgroundSize: 'cover',
          backgroundPosition: '50% 50%',
        }}
      >
        <Scrollbar>
        <Box
              sx={{
                display: 'flex',
                margin: 1,
                position: 'fixed',
                top: 0,
                right: 0
              }}
        >
          <LanguageSelect/>
        </Box>
          <Container
            maxWidth="sm"
            sx={{
              paddingTop: '10px',
              paddingBottom: '20px',
              position: 'relative',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <img
                alt="Logo"
                src={images.Logo}
                width={mobileDevice ? '250px' : '400px'}
              />
            </Box>
            <Card
              sx={{
                backgroundColor: 'background.default',
                m: (mobileDevice ? 5 : 0)
              }}
            >
              <CardContent
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  p: 4
                }}
              >
                {activeTheme !== THEMES.ACCOUNTOR && (
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'center',
                      mb: 1
                    }}
                  >
                    <div>
                      <Typography
                        color="textPrimary"
                        gutterBottom
                        variant="h4"
                        sx={{
                          fontWeight: 'bold',
                        }}
                      >
                        {t('LoginPageTitle')}
                      </Typography>
                      <Typography
                        textAlign="center"
                        color="textSecondary"
                        variant="body2"
                      >
                        {t('LoginPageSignInToUse')}
                      </Typography>
                    </div>
                  </Box>
                )}
                {activeTheme === THEMES.ACCOUNTOR && (
                  <div>
                    <Typography
                      color="#000"
                      gutterBottom
                      variant="h4"
                      sx={{
                        fontWeight: 'bold',
                      }}
                    >
                      {t('LoginPageTitleAccountor')}
                    </Typography>
                    <Typography
                      color="#000"
                      gutterBottom
                      variant="h5"
                      sx={{
                        fontWeight: 'bold',
                      }}
                    >
                      {t('LoginPageSignInToUseAccountor')}
                    </Typography>
                  </div>
                )}
                <Box
                  sx={{
                    flexGrow: 1,
                    mt: 3
                  }}
                >
                  <MSALLogin />
                </Box>
              </CardContent>
            </Card>
          </Container>
        </Scrollbar>
      </Box>
    </>
  );
};

export default Login;
