import {
  Box,
  Container,
  Grid,
  Typography,
  Divider,
  Card,
  CircularProgress,
  Breadcrumbs,
  Link,
  Button,
  TableCell,
  Table,
  TableBody,
  TableRow,
} from '@mui/material';
import { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { paymentApi } from 'src/API/paymentAPI';
import PayslipBasicDetailsCard from 'src/components/dashboard/payments/PayslipDetailCard';
import useMounted from 'src/hooks/useMounted';
import BadgeIcon from '@mui/icons-material/Badge';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import HelpIcon from '@mui/icons-material/Help';
import EuroOverlinedIcon from '../../icons/EuroOverlined';
import ChevronRightIcon from '../../icons/ChevronRight';
import SalaryChangeIcon from '../../icons/SalaryChange';
import EmojiFlagsIcon from '@mui/icons-material/EmojiFlags';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Link as RouterLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { DashboardPaymentTransactionsQueryParameters, PaymentDetailDto, PayslipPartDto, TransactionChangeDto, TransactionChangeType, TransactionDto } from 'src/types/apiSchemas';
import PayslipHeader from './PayslipHeader';
import PaymentFlagDialog from 'src/components/dashboard/payments/PaymentFlagDialog';
import PaymentFlagRemoveDialog from 'src/components/dashboard/payments/PaymentFlagRemoveDialog';
import { useAppStore } from 'src/store/mobx/appStore';
import { observer } from 'mobx-react';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { AuthorizationService, IAuthorizationService } from 'src/Services';
import { notFoundPayslipId } from 'src/constants';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import axios from 'axios';

interface PayslipDataProps {
  payslipId: string;
  /**
   * will need to disitquish if showing the payslip for the user, e.g. 'my payslip'
   * or showing the payslip in context of payment review/approval
   * because required to utilize different APIs to resolve the payslip in each case
   */
  myPayslip?: boolean;
}

export type SalaryCodeDescriptionMappings = Record<string, string>;

export const PayslipData: FC<PayslipDataProps> = observer(({ payslipId, myPayslip }) => {
  const appStore = useAppStore();
  const { user } = appStore.loginStore.get();
  const [payslip, setPayslip] = useState<PayslipPartDto[]>();
  const [isLoading, setIsLoading] = useState(true);
  const [descriptionMappings, setDescriptionMappings] = useState<SalaryCodeDescriptionMappings>({});
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const getPayslip = async () => {
      setIsLoading(true);
      let payslipData: PayslipPartDto[];

      try {
        if (myPayslip) {
          // when showing 'my payslip' for user, will need to use a distinct endpoint to resolve payslip
          payslipData = await paymentApi.getUserPayslip(user?.userId, parseInt(payslipId, 10));
        } else {
          // when showing payslip in payment review/approval, will need to use a distinct endpoint to resolve payslip
          payslipData = await paymentApi.getPayslip(parseInt(payslipId, 10));
        }

        const newFormat = payslipData[0].items === null;

        if (newFormat) {
          // Removes person id from the displayed data
          payslipData.find((pd) => pd.title === 'Palkansaaja ja Työsuhdetiedot')?.items.shift();
        }

        setPayslip(payslipData);

        try {
          const descriptions = await paymentApi.getSalaryCodeDescriptions();
          const mappings: Record<string, string> = {};

          descriptions.forEach((description) => {
            const salaryCodeMatch = payslipData.at(5)?.items?.find((item) => item?.name?.startsWith(`${description.salaryCode}`));
            if (salaryCodeMatch) {
              mappings[salaryCodeMatch.name] = description.description;
            }
          });
          setDescriptionMappings(mappings);
        } catch (err) {
          console.warn(`User has no employment and cannot see salary code descriptions: ${err}`);
        }
      } catch (err) {
        console.log(err);
        if (axios.isAxiosError(err) && err.response?.status === 404) {
          navigate('/404');
        }
      }

      setIsLoading(false);
    };

    if (payslipId !== notFoundPayslipId.toString()) {
      getPayslip();
    } else {
      setIsLoading(false);
    }
  }, [payslipId]);

  if (payslipId === notFoundPayslipId.toString()) {
    return (
      <Grid>
        <Card
          variant="outlined"
        >
          <Table
            sx={{
              borderStyle: 'inset',
              borderLeftStyle: 'hidden',
              borderRightStyle: 'hidden',
              borderTopStyle: 'hidden',
              borderBottomStyle: 'hidden'
            }}
          >
            <TableBody>
              <TableRow key="not-found">
                <TableCell sx={{ width: '25%' }}>
                  <HourglassEmptyIcon fontSize="large" />
                </TableCell>
                <TableCell sx={{ width: '75%' }}>
                  <Typography
                    color="textPrimary"
                    variant="h6"
                    sx={{
                      mb: 1,
                      mt: 1
                    }}
                  >
                    {t('PayslipNotFound')}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Card>
      </Grid>
    );
  }

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        sx={{ mt: 10 }}
        bgcolor="background.secondary"
      >
        <CircularProgress
          size={65}
        />
      </Box>
    );
  }

  return (
    <Grid>
      <Card
        variant="outlined"
        sx={{
          backgroundColor: 'background.default',
          borderColor: 'background.default'
        }}
      >
        {payslip.map((p) => (p.items ? (
          <div key={p.title}>
            <PayslipBasicDetailsCard
              payslipPartDetails={p}
              descriptionMappings={descriptionMappings}
            />
            <Divider
              sx={{
                mt: 2,
                borderStyle: 'hidden'
              }}
            />
          </div>
        ) : null))}
      </Card>
    </Grid>
  );
});

interface TransactionState {
  queryParams: DashboardPaymentTransactionsQueryParameters;
}

interface PayslipProps {
  isTransactionPayslip?: boolean;
  disablePrevNextNavigation?: boolean;
}

const Payslip: FC<PayslipProps> = observer(({ isTransactionPayslip, disablePrevNextNavigation }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { queryParams } = location.state as TransactionState || {};

  interface ChangeItemRowProps {
    children?: ReactNode;
  }

  const ChangeItemRow: FC<ChangeItemRowProps> = ({ children }) => (
    <Grid
      container
      direction="row"
      alignItems="center"
      sx={{
        mr: 2,
        paddingBottom: '12px'
      }}
    >
      {children}
    </Grid>
  );

  const icons = {
    NEW_EMPLOYMENT:
      (
        <ChangeItemRow>
          <BadgeIcon
            fontSize="large"
            sx={{ marginX: 1 }}
          />
          <Typography>
            {t('NewEmployment')}
          </Typography>
        </ChangeItemRow>
      ),
    ENDING_EMPLOYMENT:
      (
        <ChangeItemRow>
          <CancelPresentationIcon
            fontSize="large"
            sx={{ marginX: 1 }}
          />
          <Typography>
            {t('EndingEmployment')}
          </Typography>
        </ChangeItemRow>
      ),
    SALARY_CHANGE:
      (
        <ChangeItemRow>
          <SalaryChangeIcon
            fontSize="large"
            sx={{ marginX: 1 }}
          />
          <Typography>
            {t('SalaryChange')}
          </Typography>
        </ChangeItemRow>
      ),
    EXCEPTION:
      (
        <ChangeItemRow>
          <HelpIcon
            fontSize="large"
            sx={{ marginX: 1 }}
          />
          <Typography>
            {t('Exception')}
          </Typography>
        </ChangeItemRow>
      ),
    PAYLESS_ABSENCES:
      (
        <ChangeItemRow>
          <EuroOverlinedIcon
            fontSize="large"
            sx={{ marginX: 1 }}
          />
          <Typography>
            {t('PaylessAbsences')}
          </Typography>
        </ChangeItemRow>
      )
  };

  const displayTransactionChangeDetails = (transactionChange: TransactionChangeDto) => {
    switch (transactionChange.transactionChangeType) {
      case TransactionChangeType.ENDING_EMPLOYMENT:
        return transactionChange.transactionChangeDetails.map((tcd) => (
          <ChangeItemRow key={tcd.transactionChangeDetailId}>
            <CancelPresentationIcon
              fontSize="large"
              sx={{ marginX: 1 }}
            />
            <Typography>
              {`${t('EndingEmployment')} (${tcd.newValue})`}
            </Typography>
          </ChangeItemRow>
        ));
      case TransactionChangeType.EXCEPTION:
        return transactionChange.transactionChangeDetails.map((tcd) => (
          <ChangeItemRow key={tcd.transactionChangeDetailId}>
            <HelpIcon
              fontSize="large"
              sx={{ marginX: 1 }}
            />
            <Typography>
              {`${tcd.newValue ? t('ExceptionNewRow') : t('ExceptionMissingRow')} (${tcd.detailContent})`}
            </Typography>
          </ChangeItemRow>
        ));
      case TransactionChangeType.SALARY_CHANGE:
        return transactionChange.transactionChangeDetails.map((tcd) => (
          <ChangeItemRow key={tcd.transactionChangeDetailId}>
            <SalaryChangeIcon
              fontSize="large"
              sx={{ marginX: 1 }}
            />
            <Typography>
              {`${t('SalaryChange')} ${tcd.oldValue}€ → ${tcd.newValue}€ (${tcd.detailContent})`}
            </Typography>
          </ChangeItemRow>
        ));
      case TransactionChangeType.WORKTIME_CHANGE:
        return transactionChange.transactionChangeDetails.map((tcd) => (
          <ChangeItemRow key={tcd.transactionChangeDetailId}>
            <AccessTimeIcon
              fontSize="large"
              sx={{ marginX: 1 }}
            />
            <Typography>
              {`${t('WorktimeChange')}: ${tcd.detailContent} ${t('WorktimeChangeChanged')} ${tcd.oldValue} → ${tcd.newValue}`}
            </Typography>
          </ChangeItemRow>
        ));
      default:
        return null;
    }
  };

  const mounted = useMounted();
  const { payslipId, paymentId, changes, transactionId } = useParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [openFlagDialog, setOpenFlagDialog] = useState<boolean>(false);
  const [openRemoveFlagDialog, setOpenRemoveFlagDialog] = useState<boolean>(false);
  const appStore = useAppStore();
  const { user } = appStore.loginStore.get();
  const [transaction, setTransaction] = useState<TransactionDto>();
  const [nextTransaction, setNextTransaction] = useState<TransactionDto>();
  const [prevTransaction, setPrevTransaction] = useState<TransactionDto>();
  const [payment, setPayment] = useState<PaymentDetailDto | null>(null);
  const [payslipIdState, setPayslipIdState] = useState<string>(payslipId);

  const getTransactionPayslip = useCallback(async () => {
    try {
      const transactionPayslip = await paymentApi.getTransactionPayslip(parseInt(paymentId, 10), parseInt(transactionId, 10));
      if (mounted.current && transactionPayslip?.transaction?.transactionId === parseInt(transactionId, 10) && transactionPayslip.payslipId) {
        setPayslipIdState(transactionPayslip.payslipId.toString());
      } else {
        setPayslipIdState(notFoundPayslipId.toString());
      }
    } catch (err) {
      console.error(err);
    }
  }, [isTransactionPayslip, transactionId, paymentId, mounted]);

  const getPaymentAndTransactionData = useCallback(async () => {
    try {
      const transactionPromise = paymentApi.getTransactionWithNextAndPrev(parseInt(paymentId, 10), parseInt(payslipIdState, 10), queryParams);
      const paymentPromise = paymentApi.getPayment(parseInt(paymentId, 10));

      const [paymentData, transactionData] = await Promise.all([paymentPromise, transactionPromise]);

      if (mounted.current) {
        if (paymentData) { setPayment(paymentData); }
        setTransaction(transactionData[1]);
        setNextTransaction(transactionData[2]);
        setPrevTransaction(transactionData[0]);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [mounted, location, payslipIdState]);

  useEffect(() => {
    if (isTransactionPayslip && transactionId) {
      setLoading(true);
      getTransactionPayslip();
    }
  }, [mounted, location, isTransactionPayslip, transactionId]);

  useEffect(() => {
    if (payslipIdState) {
      setLoading(true);
      getPaymentAndTransactionData();
    }
  }, [mounted, location, payslipIdState]);

  useEffect(() => {
    setPayslipIdState(payslipId);
  }, [payslipId]);

  const handleCloseFlagDialogWithoutSuccess = () => {
    setOpenFlagDialog(false);
  };

  const handleCloseFlagDialogWithSuccess = (updatedTransaction: TransactionDto) => {
    setTransaction(updatedTransaction);
    setOpenFlagDialog(false);
  };

  const handleClickOpenFlagDialog = () => {
    setOpenFlagDialog(true);
  };

  const handleCloseRemoveDialogWithoutSuccess = () => {
    setOpenRemoveFlagDialog(false);
  };

  const handleCloseRemoveDialogWithSuccess = (updatedTransaction: TransactionDto) => {
    setTransaction(updatedTransaction);
    setOpenRemoveFlagDialog(false);
  };

  const handleClickOpenRemoveFlagDialog = () => {
    setOpenRemoveFlagDialog(true);
  };

  const auth: IAuthorizationService = new AuthorizationService();

  const flagButtonDisabled = () => {
    const userIsApproverOrReviewer = auth.get(user).isPaymentApproverOrReviewer;
    const currentUserReviews = payment?.reviews?.filter((r) => r.userId === user?.userId);
    const currentUserReviewedOrDeclined = currentUserReviews?.length > 0
      && (currentUserReviews[0].status === 'Reviewed' || currentUserReviews[0].status === 'Declined');
    return payment?.paymentStatus === 'Completed' || payment?.paymentStatus === 'Declined' || currentUserReviewedOrDeclined || !userIsApproverOrReviewer;
  };

  return (
    <>
      <Helmet>
        <title>{t('PayslipPageHelmet')}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          py: 8,
          mx: 2,
        }}
      >
        <Container
          maxWidth="xl"
        >
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<ChevronRightIcon fontSize="small" />}
          >
            <Link
              color="textPrimary"
              component={RouterLink}
              to="/dashboard/landingpage"
              variant="subtitle2"
            >
              {t('LandingPagePageTitle')}
            </Link>
            <Link
              color="textPrimary"
              component={RouterLink}
              to="/dashboard/payments"
              variant="subtitle2"
            >
              {t('PaymentPageTitle')}
            </Link>
            <Link
              color="textPrimary"
              component={RouterLink}
              to={`/dashboard/payments/${paymentId}`}
              variant="subtitle2"
            >
              {t('PaymentTitle')}
            </Link>
            <Link
              color="textPrimary"
              component={RouterLink}
              to={`/dashboard/payments/${paymentId}`}
              variant="subtitle2"
            >
              {t('PaymentTransactionsTitle')}
            </Link>
            <Typography
              color="text.secondary"
              variant="subtitle2"
            >
              {t('TransactionTitle')}
            </Typography>
          </Breadcrumbs>
          <Divider
            variant="middle"
            sx={{
              marginTop: 3,
              visibility: 'hidden'
            }}
          />
          <Divider
            variant="middle"
            sx={{
              marginTop: 5,
              visibility: 'hidden'
            }}
          />
          <Grid
            sx={{
              position: 'sticky',
              top: 200
            }}
          >
            {!disablePrevNextNavigation && prevTransaction && (
              <Button
                state={{ queryParams }}
                component={RouterLink}
                to={
                  isTransactionPayslip
                    // the next/previous links are different when transaction is linked to payslip and when transaction payslip is linked dynamically via payslip collection
                    ? `/dashboard/payments/${prevTransaction.paymentId}/${prevTransaction.transactionId}/payslip/${prevTransaction.changes !== undefined ? `${prevTransaction.changes}` : ''}`
                    : `/dashboard/payments/${prevTransaction.paymentId}/${prevTransaction.payslipId}${prevTransaction.changes !== undefined ? `/${prevTransaction.changes}` : ''}`
                }
                variant="contained"
                sx={{
                  position: 'relative',
                  top: -40
                }}
              >
                <NavigateBeforeIcon sx={{ mr: 1 }} />
                {t('TransactionBack')}
              </Button>
            )}
            {!disablePrevNextNavigation && nextTransaction && (
              <Button
                state={{ queryParams }}
                component={RouterLink}
                to={
                  isTransactionPayslip
                    // the next/previous links are different when transaction is linked to payslip and when transaction payslip is linked dynamically via payslip collection
                    ? `/dashboard/payments/${nextTransaction.paymentId}/${nextTransaction.transactionId}/payslip/${nextTransaction.changes !== undefined ? `${nextTransaction.changes}` : ''}`
                    : `/dashboard/payments/${nextTransaction.paymentId}/${nextTransaction.payslipId}${nextTransaction.changes !== undefined ? `/${nextTransaction.changes}` : ''}`
                }
                variant="contained"
                sx={{
                  float: 'right',
                  position: 'relative',
                  top: -40
                }}
              >
                {t('TransactionNext')}
                <NavigateNextIcon sx={{ mr: 1 }} />
              </Button>
            )}
          </Grid>
          {changes && transaction && !loading && (
            <>
              {transaction.changes && transaction.changes.map((change) => {
                const transactionChange = transaction.changeDetails.find((cd) => cd.transactionChangeType === TransactionChangeType[change]);
                return (
                  <Grid
                    key={change}
                    item
                  >
                    {transactionChange?.transactionChangeDetails.length > 0 ? displayTransactionChangeDetails(transactionChange) : icons[change]}
                  </Grid>
                );
              })}
            </>
          )}
          {transaction && !loading && (
            <>
              {transaction.flagged && auth.get(user).isPaymentApproverOrReviewer && (
                <Button
                  sx={{ marginY: 3, marginRight: 3 }}
                  variant="contained"
                  size="large"
                  onClick={handleClickOpenRemoveFlagDialog}
                  disabled={!transaction?.flagged}
                >
                  {t('RemoveFlagTitle')}
                </Button>
              )}
              {!flagButtonDisabled() && (
                <Button
                  sx={{ marginY: 3, marginRight: 3 }}
                  variant="contained"
                  size="large"
                  onClick={handleClickOpenFlagDialog}
                  disabled={flagButtonDisabled()}
                >
                  {transaction?.flagged ? t('EditFlagTitle') : t('AddFlagTitle')}
                </Button>
              )}
            </>
          )}
          {transaction && transaction?.flagged && (
            <>
              <Divider
                sx={{
                  borderStyle: 'hidden'
                }}
              />
              <Grid
                container
                bgcolor="#C70C0E"
                sx={{ bgColor: 'error', borderRadius: 1, marginBottom: 3 }}
              >
                <Grid
                  item
                  my={1}
                >
                  <EmojiFlagsIcon
                    sx={{ color: 'text.primary', marginLeft: 1 }}
                    fontSize="large"
                  />
                </Grid>
                <Grid
                  item
                  my={1}
                >
                  <Typography sx={{ marginTop: 1, marginLeft: 1 }}>
                    {transaction.comments}
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}
          {transaction && !loading && (
            <>
              <PayslipHeader transaction={transaction} />
              <Divider
                sx={{
                  mt: 2,
                  borderStyle: 'hidden'
                }}
              />
            </>
          )}
          {payslipIdState && !loading && (
            <PayslipData payslipId={payslipIdState} />
          )}
          {loading && (
            <Box
              display="flex"
              justifyContent="center"
              sx={{ mt: 10 }}
              bgcolor="background.secondary"
            >
              <CircularProgress
                size={65}
              />
            </Box>
          )}
          {transaction && (
            <PaymentFlagDialog
              openDialog={openFlagDialog}
              closeDialogWithSuccess={handleCloseFlagDialogWithSuccess}
              closeDialogWithoutSuccess={handleCloseFlagDialogWithoutSuccess}
              paymentId={parseInt(paymentId, 10)}
              transaction={transaction}
              comment={transaction.comments}
            />
          )}
          {transaction && (
            <PaymentFlagRemoveDialog
              openDialog={openRemoveFlagDialog}
              closeDialogWithSuccess={handleCloseRemoveDialogWithSuccess}
              closeDialogWithoutSuccess={handleCloseRemoveDialogWithoutSuccess}
              paymentId={parseInt(paymentId, 10)}
              transaction={transaction}
            />
          )}
        </Container>
      </Box>
    </>
  );
});

export default Payslip;
