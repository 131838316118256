import { useState, useEffect } from 'react';

const checkInterval = 60 * 5 * 1000;
const url = '/manifest.json';

const useVersionCheck = () => {
  const [currentVersion, setCurrentVersion] = useState<string | null>(null);
  const [newVersion, setNewVersion] = useState<string | null>(null);

  useEffect(() => {
    const fetchVersion = async () => {
      try {
        const response = await fetch(url, {
          cache: 'no-cache'
        });
        if (response.status >= 400) {
          throw new Error(`HTTP status code: ${response.status}`);
        }
        const { version } = await response.json();
        if (!currentVersion) {
          setCurrentVersion(version);
        } else if (currentVersion !== version) {
          setNewVersion(version);
        }
      } catch (error) {
        console.error('Error fetching version:', error);
      }
    };
    if (!currentVersion) {
      fetchVersion();
    }
    const intervalId = setInterval(() => {
      fetchVersion();
    }, checkInterval);
    return () => clearInterval(intervalId);
  }, [currentVersion]);

  return { newVersion };
};

export default useVersionCheck;