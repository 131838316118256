import type { FC } from 'react';
import PropTypes from 'prop-types';
import { managerApprovalsApi } from 'src/API/managerApprovalsApi';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Button,
  Dialog,
  TextField,
  Typography,
  Grid,
  IconButton,
  Box
} from '@mui/material';
import X from 'src/icons/X';
import { LoadingButton } from '@mui/lab';
import { useAppStore } from 'src/store/mobx/appStore';
import { observer } from 'mobx-react';

interface AttachmentDeclineDialogProps {
  managerId?: number;
  id?: number,
  openDialog: any,
  closeDialogWithSuccess: any,
  closeDialogWithoutSuccess: any,
  attachmentType?: string,
  name?: string,
}

const AttachmentDeclineDialog: FC<AttachmentDeclineDialogProps> = observer((props) => {
  const { managerId, id, openDialog, closeDialogWithSuccess, closeDialogWithoutSuccess, attachmentType, name } = props;

  const { t } = useTranslation();
  const appStore = useAppStore();
  const { user } = appStore.loginStore.get();

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    closeDialogWithoutSuccess();
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
    >
      <Formik
        initialValues={{
          reasonForDeclining: '',
          submit: null
        }}
        validationSchema={
          Yup
            .object()
            .shape({
              reasonForDeclining: Yup
                .string()
                .required(t('YupGeneralMessage'))
                .min(5, t('YupMinLength'))
            })
        }
        onSubmit={async (values, {
          resetForm,
          setStatus,
          setSubmitting
        }): Promise<void> => {
          try {
            await managerApprovalsApi.putAbsence(managerId, id, 'Declined', values.reasonForDeclining, user?.customerId);
            resetForm();
            setStatus({ success: true });
            setSubmitting(false);
            toast.success(`${t('PaymentDeclineSuccessful')}`);
            appStore.notificationStore.refreshApprovalNotifications(user);
            closeDialogWithSuccess();
          } catch (err) {
            console.log(err);
            toast.error(`${t('PaymentDeclineFailed')}`);
            setStatus({ success: false });
            setSubmitting(false);
            closeDialogWithoutSuccess();
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values
        }): JSX.Element => (
          <form
            onSubmit={handleSubmit}
          >
            <Box bgcolor="background.default">
              <Grid
                container
                sx={{
                  p: 3
                }}
              >
                <Grid
                  container
                >
                  <Grid
                    item
                    xs={11}
                  >
                    <Typography
                      color="textPrimary"
                      variant="h4"
                    >
                      {t('AttachmentReturnTitle')}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    justifyContent="flex-end"
                    xs={1}
                  >
                    <IconButton
                      aria-label="close"
                      onClick={() => { closeDialogWithoutSuccess(); }}
                      size="large"
                    >
                      <X />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  direction="row"
                >
                  <Grid
                    item
                    container
                    xs={3}
                    direction="column"
                  >
                    <Grid
                      item
                    >
                      <Typography
                        color="textSecondary"
                        variant="subtitle2"
                      >
                        {t('ApprovalNameTitle')}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      fontWeight={700}
                    >
                      {name}
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={3}
                    direction="column"
                  >
                    <Grid
                      item
                    >
                      <Typography
                        color="textSecondary"
                        variant="subtitle2"
                      >
                        {t('AttachmentTypeTitle')}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      fontWeight={700}
                    >
                      {attachmentType}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    mt: 2
                  }}
                >
                  <TextField
                    multiline
                    minRows={1}
                    maxRows={10}
                    inputProps={{ maxLength: 4000 }}
                    label={t('AbsenceReasonForReturning')}
                    error={Boolean(touched.reasonForDeclining && errors.reasonForDeclining)}
                    helperText={touched.reasonForDeclining && errors.reasonForDeclining ? errors.reasonForDeclining : ' '}
                    value={values.reasonForDeclining}
                    fullWidth
                    name="reasonForDeclining"
                    required
                    variant="outlined"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid
                  item
                  container
                  sx={{ mt: 1 }}
                  justifyContent="flex-end"
                >
                  <Button
                    sx={{
                      mr: 1,
                    }}
                    onClick={closeDialogWithoutSuccess}
                    variant="light"
                  >
                    {t('ApprovalsCancelButton')}
                  </Button>
                  <LoadingButton
                    color="primary"
                    loading={isSubmitting}
                    type="submit"
                    variant="contained"
                  >
                    {t('ReturnTitle')}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Box>

          </form>
        )}
      </Formik>
    </Dialog>
  );
});

AttachmentDeclineDialog.propTypes = {
  // @ts-ignore
  managerId: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  openDialog: PropTypes.any.isRequired,
  closeDialogWithSuccess: PropTypes.any.isRequired,
  closeDialogWithoutSuccess: PropTypes.any.isRequired
};

export default AttachmentDeclineDialog;
