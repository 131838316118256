import { Avatar, SxProps, Theme } from '@mui/material';
import { useEffect, FC } from 'react';
import { baseApi } from 'src/API/baseApi';
import { useAppStore } from 'src/store/mobx';
import { isImageSrcOK } from 'src/utils/imageUtils';
import authenticationModule from '../../azure/azure-authentication-context';

interface ProfileAvatarProps {
  src: string;
  sx?: SxProps<Theme>;
  id?: string;
  alt?: string;
}

const ProfileAvatar: FC<ProfileAvatarProps> = ({ src, sx, id, alt }) => {
  const appStore = useAppStore();

  useEffect(() => {
    const checkImageSrc = async () => {
      const srcOk = await isImageSrcOK(src);
      if (!srcOk) {
        try {
          const token = await authenticationModule.getToken();
          const newUser = await baseApi.login(token);
          if (newUser.profilePictureUrl !== src) {
            appStore.loginStore.updateAvatar(newUser.profilePictureUrl);
          }
        } catch (error) {
          console.error('Failed to refresh profile picture', error);
        }
      }
    };

    checkImageSrc();
  }, []);

  return (
    <Avatar
      id={id}
      src={src}
      alt={alt}
      sx={Array.isArray(sx) ? sx : [sx]}
    />
  );
};

export default ProfileAvatar;