import { Grid, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { MessageThreadRelatedItemDto, MessageThreadRelatedItemWithName, UserRole } from 'src/types/apiSchemas';
import MessageThreadRelatedCompanyChange from './MessageThreadRelatedCompanyChange';
import MessageThreadRelatedPersonChange from './MessageThreadRelatedPersonChange';
import { useAppStore } from 'src/store/mobx';
import { IAuthorizationService, AuthorizationService } from 'src/Services';


interface MessageThreadRelatedItemChangeProps {
  handleRelatedItemChange: (relatedItem: MessageThreadRelatedItemDto) => void
  handleDisableSaveButton?: (disable: boolean) => void
  currentRelatedItem?: MessageThreadRelatedItemWithName
  selectedCustomer: number;
  companyHasError?: boolean;
}

const MessageThreadRelatedItemChange: FC<MessageThreadRelatedItemChangeProps> = observer(({ companyHasError, handleRelatedItemChange, handleDisableSaveButton = null, currentRelatedItem, selectedCustomer }) => {
  const appStore = useAppStore();
  const { user } = appStore.loginStore.get();
  const { t } = useTranslation();
  const auth: IAuthorizationService = new AuthorizationService();
  const userIsHR = auth.get(user).is(UserRole.HR, selectedCustomer).verify();
  const userHasInternalRole = auth.get(user).hasAnyInternalRole(selectedCustomer).verify();

  const [selectedRelatedItem, setSelectedRelatedItem] = useState<MessageThreadRelatedItemDto>({ personUserId: null, companyId: null });

  const handleChange = (e) => {
    setSelectedRelatedItem({ personUserId: e?.personUserId !== undefined ? e?.personUserId : selectedRelatedItem?.personUserId, companyId: e.companyId !== undefined ? e?.companyId : selectedRelatedItem?.companyId });
  };

  useEffect(() => {
    handleRelatedItemChange(selectedRelatedItem);
  }, [selectedRelatedItem]);

  useEffect(() => {
    if (selectedCustomer !== 0) {
      if (!currentRelatedItem) {
        setSelectedRelatedItem({ personUserId: null, companyId: null });
      }
    }
  }, [selectedCustomer]);

  return (
    <>
      <Grid
        item
        xs={12}
      >
        <Grid
          item
          xs={12}
        >
          <Typography
            variant="formFieldHeader"
            sx={{ mb: 1 }}
          >
            {t('MessageThreadRelatedPersonTitle')}
          </Typography>

        </Grid>
        <Grid item xs={12} sx={{ mb: 1 }}><MessageThreadRelatedPersonChange handleRelatedItemChange={handleChange} selectedCustomer={selectedCustomer} handleDisableSaveButton={handleDisableSaveButton} currentRelatedItem={currentRelatedItem} /></Grid>
        {(userIsHR || userHasInternalRole || user.isManager) && (
          <>
            <Grid
              item
              xs={12}
            >
              <Typography
                variant="formFieldHeader"
                sx={{ mb: 1 }}
              >
                {t('MessageThreadRelatedCompanyTitle')}
              </Typography>

            </Grid>
            <Grid item xs={12} sx={{ mb: 1 }}><MessageThreadRelatedCompanyChange hasError={companyHasError} handleRelatedItemChange={handleChange} selectedCustomer={selectedCustomer} handleDisableSaveButton={handleDisableSaveButton} currentRelatedItem={currentRelatedItem} selectedRelatedItemFromPersonSelection={selectedRelatedItem} /></Grid>
          </>)}
      </Grid>
    </>
  );
});

export default MessageThreadRelatedItemChange;