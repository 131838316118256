import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'nprogress/nprogress.css';
import { StrictMode } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import App from './App';
import AuthProvider from './providers/AuthProvider';
import { AppStoreProvider, AppStore } from './store/mobx';
import { getLocale } from './utils/languageUtils';
import { createRoot } from 'react-dom/client';
import axios from 'axios';
import authenticationModule from './azure/azure-authentication-context';
import { getAuthHeader } from './utils/requestUtils';
const container = document.getElementById('root');
const root = createRoot(container);

// request interceptor to ensure up to date token for each request
axios.interceptors.request.use(async (config) => {
  const token = await authenticationModule.getToken();
  config.headers.Authorization = getAuthHeader(token);
  return config;
}, (error) => {
  return Promise.reject(error);
});


root.render(
  <StrictMode>
    <HelmetProvider>
      <AppStoreProvider store={AppStore.getInstance}>
        <StyledEngineProvider injectFirst>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={getLocale(AppStore.getInstance.settingsStore.siteSettings.language)}>
            <BrowserRouter>
              <AuthProvider>
                <App />
              </AuthProvider>
            </BrowserRouter>
          </LocalizationProvider>
        </StyledEngineProvider>
      </AppStoreProvider>
    </HelmetProvider>
  </StrictMode>);
