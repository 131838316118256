
import ReactDOM from 'react-dom';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  LexicalTypeaheadMenuPlugin,
  MenuOption,
  MenuTextMatch,
  useBasicTypeaheadTriggerMatch,
} from '@lexical/react/LexicalTypeaheadMenuPlugin';
import { $getNodeByKey, TextNode } from 'lexical';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { mergeRegister } from '@lexical/utils';
import { $createMentionNode, Mention, MentionNode } from './MentionNode';
import './MentionsNode.css';
import { baseApi } from 'src/API/baseApi';
import { InternalRole, MessageThreadVisibility, User, UserRole } from 'src/types/apiSchemas';
import { GenericAbortSignal } from 'axios';
import { GetInternalRoleTranslation, GetUserRoleTranslation } from 'src/utils/roleUtils';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { AuthorizationService, IAuthorizationService } from 'src/Services';
import { useAppStore } from 'src/store/mobx';

const PUNCTUATION =
  '\\.,\\+\\*\\?\\$\\@\\|#{}\\(\\)\\^\\-\\[\\]\\\\/!%\'"~=<>_:;';
const NAME = '\\b[A-Z][^\\s' + PUNCTUATION + ']';

const DocumentMentionsRegex = {
  NAME,
  PUNCTUATION,
};

const PUNC = DocumentMentionsRegex.PUNCTUATION;

const TRIGGERS = ['@'].join('');

// Chars we expect to see in a mention (non-space, non-punctuation).
const VALID_CHARS = '[^' + TRIGGERS + PUNC + '\\s]';

// Non-standard series of chars. Each series must be preceded and followed by
// a valid char.
const VALID_JOINS =
  '(?:' +
  '\\.[ |$]|' + // E.g. "r. " in "Mr. Smith"
  ' |' + // E.g. " " in "Josh Duck"
  '[' +
  PUNC +
  ']|' + // E.g. "-' in "Salier-Hellendag"
  ')';

const LENGTH_LIMIT = 75;

const AtSignMentionsRegex = new RegExp(
  '(^|\\s|\\()(' +
  '[' +
  TRIGGERS +
  ']' +
  '((?:' +
  VALID_CHARS +
  VALID_JOINS +
  '){0,' +
  LENGTH_LIMIT +
  '})' +
  ')$',
);

// 50 is the longest alias length limit.
const ALIAS_LENGTH_LIMIT = 50;

// Regex used to match alias.
const AtSignMentionsRegexAliasRegex = new RegExp(
  '(^|\\s|\\()(' +
  '[' +
  TRIGGERS +
  ']' +
  '((?:' +
  VALID_CHARS +
  '){0,' +
  ALIAS_LENGTH_LIMIT +
  '})' +
  ')$',
);

// At most, 5 suggestions are shown in the popup.
const SUGGESTION_LIST_LENGTH_LIMIT = 5;

const lookupService = {
  async search(filter: string, messageThreadUniqueId: string, messageThreadVisibility: MessageThreadVisibility, customerId: number, showRoleMentions: boolean, t: TFunction<'translation', undefined>, callback: (results: MentionOptions) => void, signal: GenericAbortSignal): Promise<void> {
    const users = await baseApi.getMessageThreadUsers(messageThreadUniqueId, filter, messageThreadVisibility, customerId, signal);
    const userRoles = showRoleMentions ? messageThreadVisibility.userRoles.filter(r => GetUserRoleTranslation(t, r).toLowerCase().includes(filter.toLowerCase())) : [];
    const internalRoles = showRoleMentions ? messageThreadVisibility.internalRoles.filter(r => GetInternalRoleTranslation(t, r).toLowerCase().includes(filter.toLowerCase())) : [];

    callback({ users, userRoles, internalRoles });
  },
};

interface MentionOptions {
  users: User[];
  userRoles: UserRole[];
  internalRoles: InternalRole[];
}

function useMentionLookupService(mentionString: string | null, messageThreadUniqueId: string, messageThreadVisibility: MessageThreadVisibility, customerId: number) {
  const [results, setResults] = useState<MentionOptions>();
  const { t } = useTranslation();
  const appStore = useAppStore();
  const { user } = appStore.loginStore.get();
  const auth: IAuthorizationService = new AuthorizationService();
  const hasAnyInternalRole = auth.get(user).hasAnyInternalRole(customerId).verify();
  const hasAnyUserRole = auth.get(user).hasAnyUserRole(customerId).verify();
  const showRoleMentions = hasAnyInternalRole || hasAnyUserRole;

  useEffect(() => {
    const controller = new AbortController();

    if (mentionString == null || mentionString.length < 1) {
      setResults({ users: [], userRoles: [], internalRoles: [] });
      return;
    }

    lookupService.search(mentionString, messageThreadUniqueId, messageThreadVisibility, customerId, showRoleMentions, t, (newResults) => {
      setResults(newResults);
    }, controller.signal).catch((err) => {
      console.log(err);
    });
    return () => {
      controller.abort();
    };
  }, [mentionString]);

  return results;
}

function checkForAtSignMentions(
  text: string,
  minMatchLength: number,
): MenuTextMatch | null {
  let match = AtSignMentionsRegex.exec(text);

  if (match === null) {
    match = AtSignMentionsRegexAliasRegex.exec(text);
  }
  if (match !== null) {
    // The strategy ignores leading whitespace but we need to know it's
    // length to add it to the leadOffset
    const maybeLeadingWhitespace = match[1];

    const matchingString = match[3];
    if (matchingString.length >= minMatchLength) {
      return {
        leadOffset: match.index + maybeLeadingWhitespace.length,
        matchingString,
        replaceableString: match[2],
      };
    }
  }
  return null;
}

function getPossibleQueryMatch(text: string): MenuTextMatch | null {
  return checkForAtSignMentions(text, 1);
}

export class MentionTypeaheadOption extends MenuOption {
  name: string;
  picture: JSX.Element;
  userId: number;
  userRole: UserRole;
  internalRole: InternalRole;

  constructor(name: string, picture: JSX.Element, userId: number, userRole: UserRole, internalRole: InternalRole) {
    super(name);
    this.name = name;
    this.picture = picture;
    this.userId = userId;
    this.userRole = userRole;
    this.internalRole = internalRole;
  }
}

function MentionsTypeaheadMenuItem({
  index,
  isSelected,
  onClick,
  onMouseEnter,
  option,
}: {
  index: number;
  isSelected: boolean;
  onClick: () => void;
  onMouseEnter: () => void;
  option: MentionTypeaheadOption;
}) {
  let className = 'item';
  if (isSelected) {
    className += ' selected';
  }
  return (
    <li
      key={option.key}
      tabIndex={-1}
      className={className}
      ref={option.setRefElement}
      role="option"
      aria-selected={isSelected}
      id={'typeahead-item-' + index}
      onMouseEnter={onMouseEnter}
      onClick={onClick}
    >
      {option.picture}
      <span className="text">{option.name}</span>
    </li>
  );
}

export default function NewMentionsPlugin({ onMentionAdd, onMentionRemove, messageThreadUniqueId, messageThreadVisibility, customerId }: { messageThreadUniqueId: string, onMentionAdd: (mention: Mention) => void, onMentionRemove: (mention: Mention) => void, messageThreadVisibility: MessageThreadVisibility, customerId: number }): JSX.Element | null {
  const [editor] = useLexicalComposerContext();
  const { t } = useTranslation();
  const [queryString, setQueryString] = useState<string | null>(null);

  const results = useMentionLookupService(queryString, messageThreadUniqueId, messageThreadVisibility, customerId) || { users: [], userRoles: [], internalRoles: [] };

  useEffect(() => {
    return mergeRegister(
      editor.registerMutationListener(
        MentionNode,
        (mutatedNodes, { prevEditorState }) => {
          for (let [key, mutation] of mutatedNodes) {
            if (mutation === 'destroyed') {
              const deleted = prevEditorState._nodeMap.get(key) as MentionNode;
              onMentionRemove({ userId: deleted.__userId, userRole: deleted.__userRole, internalRole: deleted.__internalRole });
            } else if (mutation === 'created') {
              editor.update(() => {
                const node = $getNodeByKey(key) as MentionNode;
                onMentionAdd({ userId: node.__userId, userRole: node.__userRole, internalRole: node.__internalRole });
              });

            }
          }
        },
        { skipInitialization: false }
      )
    );
  }, [editor]);

  const checkForSlashTriggerMatch = useBasicTypeaheadTriggerMatch('/', {
    minLength: 0,
  });

  const options = useMemo(
    () =>
      [...results.users
        .map(
          (result) =>
            new MentionTypeaheadOption(result.name, null, result.userId, null, null),
        ),
      ...results.userRoles
        .map(
          (result) =>
            new MentionTypeaheadOption(GetUserRoleTranslation(t, result), null, null, result, null),
        ),
      ...results.internalRoles
        .map(
          (result) =>
            new MentionTypeaheadOption(GetInternalRoleTranslation(t, result), null, null, null, result),
        ),
      ]
        .slice(0, SUGGESTION_LIST_LENGTH_LIMIT),
    [results],
  );

  const onSelectOption = useCallback(
    (
      selectedOption: MentionTypeaheadOption,
      nodeToReplace: TextNode | null,
      closeMenu: () => void,
    ) => {
      editor.update(() => {
        const mentionNode = $createMentionNode(selectedOption.name, selectedOption.userId, selectedOption.userRole, selectedOption.internalRole);
        if (nodeToReplace) {
          nodeToReplace.replace(mentionNode);
        }
        mentionNode.select();
        closeMenu();
      });
    },
    [editor],
  );

  const checkForMentionMatch = useCallback(
    (text: string) => {
      const slashMatch = checkForSlashTriggerMatch(text, editor);
      if (slashMatch !== null) {
        return null;
      }
      return getPossibleQueryMatch(text);
    },
    [checkForSlashTriggerMatch, editor],
  );

  return (
    <LexicalTypeaheadMenuPlugin<MentionTypeaheadOption>
      onQueryChange={setQueryString}
      onSelectOption={onSelectOption}
      triggerFn={checkForMentionMatch}
      i18nIsDynamicList
      options={options}
      menuRenderFn={(
        anchorElementRef,
        { selectedIndex, selectOptionAndCleanUp, setHighlightedIndex },
      ) =>
        anchorElementRef.current && results.users.length || results.userRoles.length || results.internalRoles.length
          ? ReactDOM.createPortal(
            <div style={{ position: 'relative' }}>
              <div className="typeahead-popover mentions-menu" style={{ position: 'absolute', bottom: 40 }}>
                <ul>
                  {options.map((option, i: number) => (
                    <MentionsTypeaheadMenuItem
                      index={i}
                      isSelected={selectedIndex === i}
                      onClick={() => {
                        setHighlightedIndex(i);
                        selectOptionAndCleanUp(option);
                      }}
                      onMouseEnter={() => {
                        setHighlightedIndex(i);
                      }}
                      key={option.key}
                      option={option}
                    />
                  ))}
                </ul>
              </div>
            </div>,
            anchorElementRef.current,
          )
          : null
      }
    />
  );
}