import { FC, useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Alert, Link, Typography } from '@mui/material';
import useMounted from 'src/hooks/useMounted';
import { manageSubstitutionsPath } from 'src/constants';
import { managersApi } from 'src/API/managersApi';
import { useTranslation } from 'react-i18next';
import { AuthorizationService, IAuthorizationService } from 'src/Services';
import { useAppStore } from 'src/store/mobx';

interface ManagerSubstitutionAlertProps {
  isLoading?: boolean;
}

const ManagerSubstitutionAlert: FC<ManagerSubstitutionAlertProps> = (props) => {
  const appStore = useAppStore();
  const { user } = appStore.loginStore.get();
  const auth: IAuthorizationService = new AuthorizationService();
  const { isLoading } = props;
  const [mySubstituteCount, setMySubstituteCount] = useState<number>(null);
  const mounted = useMounted();
  const { t } = useTranslation();
  const isManager = auth.get(user).hasManagerAccess;

  const getMySubstitutes = useCallback(async () => {
    try {
      const mySubstitutesResult = await managersApi.getAllManagerSubstitutes();
      if (mounted.current) {
        setMySubstituteCount(mySubstitutesResult?.length);
      }
    } catch (err) {
      console.error(`Failed to get substitutes: ${err}`);
    }
  }, [mounted]);

  useEffect(() => {
    if (isManager) {
      getMySubstitutes();
    }
  }, [mounted]);

  if (isLoading || mySubstituteCount === null || mySubstituteCount > 0) {
    return null;
  }

  return (
    <Alert
      severity="warning"
      sx={{
        marginBottom: 2,
      }}
      color="warning"
    >
      <Typography
        color="textPrimary"
        variant="subtitle2"
        display="inline-block"
      >
        {t('ManagerSubstitutionNotSubstitutionsAlert1')}
      </Typography>
      &nbsp;
      <Link
        display="inline-block"
        color="textSecondary"
        variant="subtitle2"
        component={RouterLink}
        to={`/dashboard/${manageSubstitutionsPath}`}
      >
        {`${t('ManagerSubstitutionNotSubstitutionsAlert2')}.`}
      </Link>
    </Alert>
  );
};

ManagerSubstitutionAlert.propTypes = {
  isLoading: PropTypes.bool,
};

export default ManagerSubstitutionAlert;
