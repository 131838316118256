import { makeAutoObservable, runInAction } from 'mobx';
import { StorageKeys } from 'src/constants';
import { autoSaveToStorage } from './autoSaveToStorage';
import { ApprovalSort, DateRange, MessageCategoryType, MessageThreadAssignmentStatus, MessageThreadSort, MessageThreadStatus, SLAPriority, SortDirection } from 'src/types/apiSchemas';
import { baseApi } from 'src/API/baseApi';
import { MessageThreadHeader } from 'src/pages/dashboard/Messages';

type NumberScopedValue<T> = { [key: number]: T };
type StringScopedValue<T> = { [key: string]: T };

export interface MessageView {
  customerId: number;
  name: string;
  messagesSearch: string
  messagesAssignee: (number | string | MessageThreadAssignmentStatus)[],
  messagesCustomerAssignee: (number | string | MessageThreadAssignmentStatus)[],
  messagesCategory: number[],
  messagesCategoryType: MessageCategoryType[],
  messagesSLA: SLAPriority[];
  messagesStatus: MessageThreadStatus[],
  messagesCustomer: string[],
  messagesSort: MessageThreadSort;
  messagesSortDirection: SortDirection;
  urlName: string
  messagesRelatedCompany: number[]
}

export interface ApprovalsFilters {
  selectedManager: string,
  selectedStatus: string,
  selectedType: string, 
  includeSubstitutions: boolean,
  submitterQuery: string,
  allApprovals: boolean,
  orderBy: ApprovalSort,
  orderDirection: 'asc' | 'desc'
}

class StateStore {
  transactionSearch: StringScopedValue<string> = {};
  transactionFilters: StringScopedValue<string[]> = {};
  payslipSearch: StringScopedValue<string> = {};
  messagesSearch: NumberScopedValue<string> = {};
  messagesAssignee: NumberScopedValue<number | (number | string | MessageThreadAssignmentStatus)[]> = {};
  includeBothAssignees: boolean = false;
  messagesCustomerAssignee: NumberScopedValue<number | (number | string | MessageThreadAssignmentStatus)[]> = {};
  messagesStatus: NumberScopedValue<MessageThreadStatus | MessageThreadStatus[]> = {};
  messagesCategory: NumberScopedValue<number | number[]> = {};
  messagesCategoryType: NumberScopedValue<MessageCategoryType[]> = {};
  messagesSLA: NumberScopedValue<SLAPriority[]> = {};
  messagesRelatedCompany: NumberScopedValue<number | number[]> = {};
  messagesCustomer: NumberScopedValue<string | string[]> = {};
  messagesSort: NumberScopedValue<MessageThreadSort> = {};
  messagesSortDirection: NumberScopedValue<SortDirection> = {};
  messagesLastView: NumberScopedValue<string> = {};
  messagesPageNumber: StringScopedValue<number> = {};
  paymentsSearch: NumberScopedValue<string> = {};
  paymentsStatus: NumberScopedValue<string> = {};
  paymentsDate: NumberScopedValue<DateRange> = {};
  paymentsPageSize: NumberScopedValue<number> = {};
  paymentsPageNumber: NumberScopedValue<number> = {};
  messageViews: MessageView[] = [];
  usersNameFilter: StringScopedValue<string> = {};
  usersRoleFilter: StringScopedValue<string> = {};
  usersPageNumber: NumberScopedValue<number> = {};
  usersPageSize: NumberScopedValue<number> = {};
  hiddenHeaders: NumberScopedValue<MessageThreadHeader[]> = {};
  approvalFilters: NumberScopedValue<ApprovalsFilters> = {};

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
    autoSaveToStorage(this, StorageKeys.stateStoreKey);
  }

  setTransactionSearch(value: string, paymentId: string) {
    this.transactionSearch[paymentId] = value;
  }

  setTransactionFilters(value: string[], paymentId: string) {
    this.transactionFilters[paymentId] = value;
  }

  setPayslipSearch(value: string, paymentId: string) {
    this.payslipSearch[paymentId] = value;
  }

  setMessagesSearch(value: string, customerId: number) {
    this.messagesSearch[customerId] = value;
  }

  setMessagesAssignee(value: (number | string | MessageThreadAssignmentStatus)[], customerId: number) {
    this.messagesAssignee[customerId] = value;
  }

  setIncludeBothAssignees(value: boolean) {
    this.includeBothAssignees = value;
  }

  setMessagesCustomerAssignee(value: (number | string | MessageThreadAssignmentStatus)[], customerId: number) {
    this.messagesCustomerAssignee[customerId] = value;
  }

  setMessagesStatus(value: MessageThreadStatus[], customerId: number) {
    this.messagesStatus[customerId] = value;
  }

  setMessagesCategory(value: number[], customerId: number) {
    this.messagesCategory[customerId] = value;
  }

  setMessagesCategoryType(value: MessageCategoryType[], customerId: number) {
    this.messagesCategoryType[customerId] = value;
  }

  setMessagesSLA(value: SLAPriority[], customerId: number) {
    this.messagesSLA[customerId] = value;
  }

  setMessagesCustomer(value: string[], customerId: number) {
    this.messagesCustomer[customerId] = value;
  }

  setMessagesRelatedCompany(value: number[], customerId: number) {
    this.messagesRelatedCompany[customerId] = value;
  }

  setMessagesSort(value: MessageThreadSort, customerId: number) {
    this.messagesSort[customerId] = value;
  }

  setMessagesSortDirection(value: SortDirection, customerId: number) {
    this.messagesSortDirection[customerId] = value;
  }

  setMessagesLastView(value: string, customerId: number) {
    this.messagesLastView[customerId] = value;
  }

  setPaymentsSearch(value: string, customerId: number) {
    this.paymentsSearch[customerId] = value;
  }

  setPaymentsStatus(value: string, customerId: number) {
    this.paymentsStatus[customerId] = value;
  }

  setPaymentsDate(value: DateRange, customerId: number) {
    this.paymentsDate[customerId] = value;
  }

  setPaymentsPageSize(value: number, customerId: number) {
    this.paymentsPageSize[customerId] = value;
  }

  setPaymentsPageNumber(value: number, customerId: number) {
    this.paymentsPageNumber[customerId] = value;
  }

  setHiddenHeaders(value: MessageThreadHeader[], customerId: number) {
    this.hiddenHeaders[customerId] = value;
  }

  fetchMessageViews = async () => {
    const res = await baseApi.getUserMessageViews();
    runInAction(() => {
      this.messageViews = res;
    });
  };

  createMessageView = (value: MessageView) => {
    this.messageViews = [...this.messageViews, value];
    this.syncMessageViews();
  };

  saveMessageView = (value: MessageView) => {
    this.messageViews = this.messageViews.map(f => f.urlName === value.urlName && f.customerId === value.customerId ? value : f);
    this.syncMessageViews();
  };

  deleteMessageView = (value: MessageView) => {
    this.messageViews = this.messageViews.filter(f => !(f.urlName === value.urlName && f.customerId === value.customerId));
    this.syncMessageViews();
  };

  syncMessageViews = () => {
    baseApi.updateUserMessageViews(this.messageViews.map(mv => ({ ...mv, messagesAssignee: mv.messagesAssignee.map(ma => `${ma}`) })));
  };

  setMessagesPageNumber = (value: number, messageViewName: string) => {
    this.messagesPageNumber[messageViewName] = value;
  };

  setUsersNameFilter(value: string, customerId: number) {
    this.usersNameFilter[customerId] = value;
  }

  setUsersRoleFilter(value: string, customerId: number) {
    this.usersRoleFilter[customerId] = value;
  }

  setUsersPageNumber(value: number, customerId: number) {
    this.usersPageNumber[customerId] = value;
  }

  setUsersPageSize(value: number, customerId: number) {
    this.usersPageSize[customerId] = value;
  }

  setApprovalFilters(value: ApprovalsFilters, customerId: number) {
    this.approvalFilters[customerId] = value;
  }
}

export default new StateStore();