import { theme } from './theme';
import { ImageNode } from './ImageNode';
import { MentionNode } from './MentionNode';
import { ListItemNode, ListNode } from '@lexical/list';
import { AutoLinkNode } from '@lexical/link';

export const editorConfig = {
  namespace: 'Message Editor',
  nodes: [ListItemNode, ListNode, ImageNode, AutoLinkNode, MentionNode],
  // Handling of errors during update
  onError(error: Error) {
    throw error;
  },
  theme
};