import { LoadingButton } from '@mui/lab';
import { Button, FormHelperText, Grid, MenuItem, Select, TextField, Typography, Box, FormControlLabel, Checkbox, Tooltip } from '@mui/material';
import { Formik } from 'formik';
import { FC, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { baseApi } from 'src/API/baseApi';
import { useAppStore } from 'src/store/mobx';
import { CompanyReferenceDto, Customer, Feature, InternalRole, MessageAttachmentCreation, MessageThread, MessageThreadRelatedItemDto, MessageThreadVisibility, SLAPriority, SubordinateDto, TranslatedMessageCategory, UserRole } from 'src/types/apiSchemas';
import * as Yup from 'yup';
import MessageThreadVisibilityModal from './MessageThreadVisibilityModal';
import CommonModal from 'src/components/common/CommonModal';
import { GetInternalRoleTranslation, GetUserRoleTranslation } from 'src/utils/roleUtils';
import { AuthorizationService, IAuthorizationService } from 'src/Services';
import { AttachmentPreview } from 'src/pages/dashboard/UseAttachments';
import { allCustomersId } from 'src/constants';
import InfoIcon from '@mui/icons-material/Info';
import UserDisplay from './UserDisplay';
import MessageThreadRelatedItemChange from './MessageThreadRelatedItemChange';
import MessageEditor from 'src/components/MessageEditor/MessageEditor';

interface CreateMessageFormProps {
  onClose: () => void;
  onSend: () => void;
  showTeamMembers?: boolean;
}

const CreateMessageForm: FC<CreateMessageFormProps> = ({ onClose, onSend, showTeamMembers }) => {
  const appStore = useAppStore();
  const { user } = appStore.loginStore.get();
  const [messageCategories, setMessageCategories] = useState<TranslatedMessageCategory[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCategories, setIsLoadingCategories] = useState(false);
  const [isVisibilityModalOpen, setIsVisibilityModalOpen] = useState(false);
  const [category, setCategory] = useState('');
  const [categoryDescription, setCategoryDescription] = useState('');
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState(user.customerId);
  const initialVisibility = { userRoles: [], internalRoles: [], managerHasAccess: false, users: [] };
  const [visibility, setVisibility] = useState<MessageThreadVisibility>(initialVisibility);
  const { t } = useTranslation();
  const auth: IAuthorizationService = new AuthorizationService();
  const [attachments, setAttachments] = useState<MessageAttachmentCreation[]>([]);
  const [userMentions, setUserMentions] = useState<number[]>([]);
  const [userRoleMentions, setUserRoleMentions] = useState<UserRole[]>([]);
  const [internalRoleMentions, setInternalRoleMentions] = useState<InternalRole[]>([]);
  const [priority, setPriority] = useState<SLAPriority>(SLAPriority.Normal);
  const [teamMembers, setTeamMembers] = useState<SubordinateDto[]>([]);
  const [selectedTeamMembers, setSelectedTeamMembers] = useState<number[]>([]);
  const [messageThreadSelectedRelatedItem, setMessageThreadSelectedRelatedItem] = useState<MessageThreadRelatedItemDto>();
  const [messageSent, setMessageSent] = useState(false);

  const hasAnyInternalRole = auth.get(user).hasAnyInternalRole(selectedCustomer).verify();
  const hasAnyUserRole = auth.get(user).hasAnyUserRole(selectedCustomer).verify();
  const isCustomerHR = auth.get(user).is(UserRole.HR, selectedCustomer).verify();
  const canChangeVisibility = hasAnyInternalRole || hasAnyUserRole;
  const canChangePriority = hasAnyInternalRole || isCustomerHR;
  const canChangeRelatedItem = hasAnyInternalRole || isCustomerHR || (user.isManager && user.employmentCustomerId === selectedCustomer);
  const [relatedCompanyOptions, setRelatedCompanyOptions] = useState<CompanyReferenceDto[]>([]);
  const requireRelatedCompany = user.isManager && !hasAnyInternalRole && !isCustomerHR && relatedCompanyOptions.length > 0;

  const messageThreadVisibility = [...visibility.internalRoles.map((r) => GetInternalRoleTranslation(t, r)), ...visibility.userRoles.map((r) => GetUserRoleTranslation(t, r)), ...visibility.users.map((u) => u.name), ...(visibility.managerHasAccess ? [t('ApprovalsManager')] : [])];

  const handleRelatedItemChange = (relatedItem: MessageThreadRelatedItemDto) => {
    setMessageThreadSelectedRelatedItem(relatedItem);
  };

  useEffect(() => {
    const updateCategories = async () => {
      setIsLoadingCategories(true);
      try {
        const response = await baseApi.getMessageCategories(selectedCustomer);
        const filteredCategories = response.filter(c => !c.emailCategory && !c.technicalCategory);
        setMessageCategories(filteredCategories);

      } catch (err) {
        console.log(err);
      }
      setIsLoadingCategories(false);
    };
    if (selectedCustomer)
      updateCategories();
  }, [selectedCustomer]);

  useEffect(() => {
    const getUserCustomers = async () => {
      try {
        const response = await baseApi.getUserCustomers();
        const filtereCustomers = response.filter((c) => c.customerFeatures?.includes(Feature.Messaging));
        setCustomers(filtereCustomers);
        if (filtereCustomers.length > 0)
          setSelectedCustomer(filtereCustomers[0].customerId);
      } catch (err) {
        console.log(err);
      }
    };

    if (user.customerId === allCustomersId)
      getUserCustomers();
  }, []);

  useEffect(() => {
    const getSubordinates = async () => {
      const result = await baseApi.getSubordinates(user?.customerId);
      setTeamMembers(result);
    };
    if (showTeamMembers) {
      getSubordinates();
    }
  }, []);

  useEffect(() => {
    const getRelatedCompanies = async () => {
      try {
        const response = await baseApi.getMessageThreadRelatedItemOptions(selectedCustomer, '');
        setRelatedCompanyOptions(response.companies);
      } catch (err) {
        console.log(err);
      }
    };
    if (selectedCustomer) {
      getRelatedCompanies();
    } else {
      setRelatedCompanyOptions([]);
    }
  }, [selectedCustomer]);

  const hasSLAEnabled = (id: string): boolean => {
    return messageCategories.find((c) => c.id === parseInt(id))?.slaMonitoring;
  };

  return (
    <Formik
      initialValues={{
        subject: '',
        category: '',
        message: '',
        richTextContent: ''
      }}
      onSubmit={async (values) => {
        setIsLoading(true);

        const threadVisibility: MessageThreadVisibility = showTeamMembers ? {
          users: selectedTeamMembers.map(u => ({ id: u })),
          managerHasAccess: false,
          userRoles: [],
          internalRoles: []
        } : canChangeVisibility ? visibility : undefined;

        const messageThread: MessageThread = {
          categoryId: parseInt(category, 10),
          subject: values.subject,
          message: values.message,
          richTextContent: values.richTextContent,
          attachmentIds: attachments.map(a => a.messageAttachmentId),
          customerId: selectedCustomer,
          messageThreadVisibility: threadVisibility,
          priority: hasSLAEnabled(category) ? priority : undefined,
          relatedCompanyId: messageThreadSelectedRelatedItem?.companyId,
          relatedPersonId: messageThreadSelectedRelatedItem?.personUserId,
          userMentions,
          userRoleMentions,
          internalRoleMentions
        };
        try {
          await baseApi.postMessageThread(messageThread);
          toast.success(t('MessagesPageMessageSent'));
          setAttachments([]);
          setUserMentions([]);
          setUserRoleMentions([]);
          setInternalRoleMentions([]);
          setMessageSent(true);
          onSend();
        } catch (err) {
          console.log(err);
          toast.error(t('MessagesPageMessageSendingFailed'));
        }
        setIsLoading(false);
      }}
      validationSchema={
        Yup.object().shape({
          subject: Yup.string().required(t('YupGeneralMessage')),
          category: Yup.string().required(t('YupGeneralMessage')),
          message: Yup.string().required(t('YupGeneralMessage'))
        })
      }
    >
      {({ values, touched, errors, handleChange, handleSubmit, setFieldValue }) => (
        <form onSubmit={handleSubmit}>
          <Grid
            container
            gap={3}
          >
            <Grid
              item
              xs={12}
            >
              <Typography
                variant="formFieldHeader"
                sx={{ mb: 1 }}
              >
                {t('MessagesPageMessageSubject')}
              </Typography>
              <TextField
                fullWidth
                autoComplete="off"
                onChange={handleChange}
                name="subject"
                error={Boolean(touched.subject && errors.subject)}
                helperText={(touched.subject && errors.subject)}
                value={values.subject}
              />
            </Grid>
            {user.customerId === allCustomersId &&
              <Grid item xs={12}>
                <Typography
                  variant="formFieldHeader"
                  sx={{ mb: 1 }}
                >
                  {t('Customer')}
                </Typography>
                <Select value={selectedCustomer ? selectedCustomer : ''}
                  onChange={(e) => {
                    setSelectedCustomer(parseInt(e.target.value as string, 10));
                    setCategory('');
                    setVisibility(initialVisibility);
                  }}
                >
                  {customers.map(c => <MenuItem value={c.customerId} key={c.customerId} >{c.name}</MenuItem>)}
                </Select>
              </Grid>
            }
            <Grid
              item
              xs={4}
            >
              <Typography
                variant="formFieldHeader"
                sx={{ mb: 1 }}
              >
                {t('MessagesPageMessageCategory')}
              </Typography>
              <Select
                value={isLoadingCategories ? '' : category}
                name="category"
                error={Boolean(touched.category && errors.category)}
                onChange={(e) => {
                  setCategory(e.target.value);
                  const selectedCategory = messageCategories.find((c) => c.id === parseInt(e.target.value, 10));
                  setCategoryDescription(selectedCategory.description);
                  setVisibility({
                    internalRoles: selectedCategory.messageCategoryInternalRoles,
                    userRoles: selectedCategory.messageCategoryRoles,
                    managerHasAccess: selectedCategory.managerHasAccess,
                    users: []
                  });
                  handleChange(e);
                }}
                sx={{ width: 300, maxWidth: '100%' }}
              >
                {messageCategories.map((mc) => (
                  <MenuItem value={mc.id} key={mc.id}>
                    {mc.name}
                  </MenuItem>
                ))}
              </Select>
              {errors.category && <FormHelperText error>{errors.category}</FormHelperText>}
            </Grid>
            {categoryDescription &&
              <Grid
                item
                xs={12}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <InfoIcon fontSize="small" />
                  <Typography sx={{ ml: 1 }}>
                    {categoryDescription}
                  </Typography>
                </Box>
              </Grid>}
            <Grid
              item
              xs={4}
            >
              {category && hasSLAEnabled(category) && canChangePriority &&
                <>
                  <Typography
                    variant="formFieldHeader"
                    sx={{ mb: 1 }}
                  >
                    {t('MessageThreadPriority')}
                  </Typography>
                  <Select
                    name="priority"
                    sx={{ width: 300, maxWidth: '100%' }}
                    value={priority}
                    onChange={(e) => { setPriority(e.target.value as SLAPriority); }}
                  >
                    <MenuItem
                      value={SLAPriority.Low}
                      key={SLAPriority.Low}
                    >
                      {t('MessageThreadPriorityLow')}
                    </MenuItem>
                    <MenuItem
                      value={SLAPriority.Normal}
                      key={SLAPriority.Normal}
                    >
                      {t('MessageThreadPriorityNormal')}
                    </MenuItem>
                    <MenuItem
                      value={SLAPriority.High}
                      key={SLAPriority.High}
                    >
                      {t('MessageThreadPriorityHigh')}
                    </MenuItem>
                  </Select>
                </>
              }
            </Grid>
            {canChangeVisibility && !showTeamMembers && (
              <Grid
                item
                xs={12}
              >
                <Typography
                  variant="formFieldHeader"
                  sx={{ mb: 1 }}
                >
                  {t('MessagesPageVisibility')}
                </Typography>
                <div style={{ marginBottom: 15 }}>{messageThreadVisibility.join(', ')}</div>
                <Button
                  variant="light"
                  onClick={() => { setIsVisibilityModalOpen(true); }}
                >
                  {t('MessagesPageChangeVisibility')}
                </Button>
              </Grid>
            )}
            {showTeamMembers &&
              <Grid item xs={12}>
                <div style={{ display: 'flex', marginBottom: 20, alignItems: 'center' }}>
                  <div>
                    <Typography
                      variant="formFieldHeader"
                      sx={{ mb: 1 }}
                    >
                      {t('MessagesPageVisibility')}
                    </Typography>
                  </div>
                  <div style={{ marginLeft: 'auto', marginRight: 20 }}>
                    {teamMembers.length > 0 &&
                      <Button variant="contained"
                        onClick={() => {
                          if (selectedTeamMembers.length < teamMembers.length)
                            setSelectedTeamMembers(teamMembers.map(tm => tm.id));
                          else
                            setSelectedTeamMembers([]);
                        }}
                      >
                        {selectedTeamMembers.length < teamMembers.length ? t('ApprovalsSelectAll') : t('RemoveAll')}
                      </Button>}
                  </div>
                </div>
                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(340px, 1fr))', gap: 20, maxHeight: 300, overflow: 'auto' }}>
                  {teamMembers.map((u) => (
                    <div
                      key={u.id}
                      style={{ paddingLeft: 11, overflow: 'hidden' }}
                    >
                      <Tooltip title={<><div>{u.name}</div><div>{t('EmployeeId')}: {u.employeeId}</div></>} placement="top">
                        <FormControlLabel
                          control={(
                            <Checkbox
                              sx={{ mr: 1 }}
                              checked={selectedTeamMembers.some((ur) => ur === u.id)}
                              onChange={(event) => {
                                setSelectedTeamMembers((prev) => (event.target.checked ? [...prev, u.id] : prev.filter((p) => p !== u.id)));
                              }}
                            />
                          )}
                          label={(
                            <UserDisplay
                              displayName={u.name}
                              secondaryText={u.email}
                            />
                          )}
                        />
                      </Tooltip>
                    </div>
                  ))}
                </div>
              </Grid>}
            {canChangeRelatedItem && (
              <MessageThreadRelatedItemChange companyHasError={requireRelatedCompany && !messageThreadSelectedRelatedItem?.companyId} handleRelatedItemChange={handleRelatedItemChange} selectedCustomer={selectedCustomer} />
            )}
            <Grid item xs={12}>
              <MessageEditor
                maxHeight={300}
                messageThreadVisibility={visibility}
                customerId={selectedCustomer}
                onChange={({ richTextContent, plainTextContent }) => {
                  setFieldValue('message', plainTextContent);
                  setFieldValue('richTextContent', richTextContent);
                }}
                onAttachmentsAdd={(attachs) => {
                  setAttachments(prev => [...prev, ...attachs]);
                }}
                onAttachmentsRemove={(url) => {
                  setAttachments(prev => [...prev.filter(a => a.url !== url)]);
                }}
                onMentionAdd={(mention) => {
                  if (mention.userId) {
                    setUserMentions(prev => [...prev, mention.userId]);
                  } else if (mention.userRole) {
                    setUserRoleMentions(prev => [...prev, mention.userRole]);
                  } else if (mention.internalRole) {
                    setInternalRoleMentions(prev => [...prev, mention.internalRole]);
                  }
                }}
                onMentionRemove={(mention) => {
                  if (mention.userId) {
                    setUserMentions(prev => {
                      const index = prev.indexOf(mention.userId);
                      const arr = [...prev];
                      if (index > -1) {
                        arr.splice(index, 1);
                      }
                      return arr;
                    });
                  } else if (mention.userRole) {
                    setUserRoleMentions(prev => {
                      const index = prev.indexOf(mention.userRole);
                      const arr = [...prev];
                      if (index > -1) {
                        arr.splice(index, 1);
                      }
                      return arr;
                    });
                  } else if (mention.internalRole) {
                    setInternalRoleMentions(prev => {
                      const index = prev.indexOf(mention.internalRole);
                      const arr = [...prev];
                      if (index > -1) {
                        arr.splice(index, 1);
                      }
                      return arr;
                    });
                  }
                }}
                messageSent={messageSent}
                showToolbar
              />
              {attachments.length > 0 &&
                <div style={{ display: 'flex', gap: 20, flexWrap: 'wrap', marginTop: 25 }}>
                  {/* eslint-disable-next-line react/no-array-index-key */}
                  {attachments.map((a, i) =>
                    values.richTextContent.includes(a.urlWithoutSas) ? null :
                      <AttachmentPreview
                        displayRemoveButton={!isLoading}
                        onRemove={() => {
                          setAttachments(prev => {
                            const vals = [...prev];
                            vals.splice(i, 1);
                            return vals;
                          });
                        }}
                        attachment={a}
                        key={i}
                        t={t}
                      />)}
                </div>}
            </Grid>
            <Grid
              container
              gap={2}
            >
              <Grid item>
                <LoadingButton
                  variant="contained"
                  type="submit"
                  loading={isLoading}
                  disabled={(showTeamMembers && selectedTeamMembers.length < 1) || (requireRelatedCompany && !messageThreadSelectedRelatedItem?.companyId)}
                >
                  {t('ButtonSend')}
                </LoadingButton>
              </Grid>
              <Grid item>
                <Button
                  variant="light"
                  onClick={onClose}
                >
                  {t('ButtonCancel')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <CommonModal
            open={isVisibilityModalOpen}
            width={1200}
          >
            <div style={{ height: '80vh', overflow: 'auto' }}>
              <MessageThreadVisibilityModal
                initialVisibility={visibility}
                customerId={selectedCustomer}
                onClose={() => {
                  setIsVisibilityModalOpen(false);
                }}
                onVisibilityUpdate={(updatedVisibility) => {
                  setVisibility(updatedVisibility);
                }}
              />
            </div>
          </CommonModal>
        </form>
      )}
    </Formik>
  );
};

export default CreateMessageForm;
