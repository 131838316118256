import * as React from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { LANGUAGES } from 'src/constants';
import { DropDownOption } from 'src/types/dropDownOption';
import { ListItemIcon } from '@mui/material';
import { useAppStore } from 'src/store/mobx';
import FlagEn from 'src/icons/FlagEn';
import FlagFi from 'src/icons/FlagFi';
import FlagSe from 'src/icons/FlagSe';

export const getFlagIcon = (languageCode: string) => {
  if (languageCode === 'fi') {
    return <FlagFi/>;
  } else if (languageCode === 'en') {
    return <FlagEn/>;
  } else if (languageCode === 'sv') {
    return <FlagSe/>;
  } else {
    return <QuestionMarkIcon/>;
  }
};

export default function LanguageSelect() {
  const appStore = useAppStore();
  const settings = appStore.settingsStore.get();
  const [language, setLanguage] = React.useState(settings.language ?? 'fi');

  const handleChange = (event: SelectChangeEvent) => {
    const selectedLanguage = event.target.value as string;
    setLanguage(selectedLanguage);
    appStore.settingsStore.set({ ...settings, language: selectedLanguage });
  };

  return (
    <Box sx={{ minWidth: 5 }}>
        <Select
          id="language-select"
          value={language}
          onChange={handleChange}
        >
          {LANGUAGES.map((item: DropDownOption) => (
            <MenuItem
            key={item.value}
            value={item.value}
            >
                <ListItemIcon>
                {getFlagIcon(item.value)}
                </ListItemIcon>
            </MenuItem>
          ))}
        </Select>
    </Box>
  );
}
