import { FC, ReactNode, useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Login from '../pages/authentication/Login';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useAppStore } from 'src/store/mobx/appStore';
import { observer } from 'mobx-react';
import authenticationModule from '../azure/azure-authentication-context';

interface AuthGuardProps {
  children: ReactNode;
}

const AuthGuard: FC<AuthGuardProps> = observer((props) => {
  const { children } = props;
  const appStore = useAppStore();
  const location = useLocation();
  const { t } = useTranslation();
  const [requestedLocation, setRequestedLocation] = useState(null);
  const { user } = appStore.loginStore.get();

  // effect hook to validate authentication
  useEffect(() => {
    const validateAuth = async (): Promise<void> => {
      try {
        await authenticationModule.getToken();
        if (!authenticationModule.isAuthenticated) {
          appStore.loginStore.logout();
          toast.error(t('SessionExpiredToast'), {
            id: 'sessionExpired',
          });
        }
      } catch (error) {
        appStore.loginStore.logout();
        toast.error(t('SessionExpiredToast'), {
          id: 'sessionExpired',
        });
      }
    };
    validateAuth();
  }, [authenticationModule.isAuthenticated, location.pathname]);

  if (!appStore.loginStore.get().isAuthenticated || !user.accessPermissions) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }

    return <Login />;
  }

  // This is done so that in case the route changes by any chance through other
  // means between the moment of request and the render we navigate to the initially
  // requested route.
  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
});

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;
