import axios from 'axios';
import authenticationModule from 'src/azure/azure-authentication-context';
import { getBackendUrl } from 'src/utils/requestUtils';
import { AuthorizationService, CacheService, IAuthorizationService, ICacheService } from 'src/Services';
import { SiteUser } from 'src/types/siteUser';
import { LanguageCode, ReportingNavigation } from 'src/types/apiSchemas';

export type DemoReportUserTrackingAction = 'AutoOpen' | 'ClickOrder' | 'ClickOpen';

class ReportServiceApi {
  private cacheService: ICacheService = new CacheService();
  private static readonly queryStringKeyPrincipal: string = 'principal';
  public readonly principalCacheKey: string = 'principal-cache-key';

  private auth: IAuthorizationService = new AuthorizationService();

  /**
   * resolves username from querystring principal=user.name@domain.fi
   * and caches it
   */
  private get getImpersonatedUserId(): string {
    const queryString = new URLSearchParams(window.location.search);
    const principalValue = queryString.get(ReportServiceApi.queryStringKeyPrincipal);

    if (principalValue) {
      this.cacheService.set<string>(this.principalCacheKey, principalValue);
    }

    return this.cacheService.get<string>(this.principalCacheKey);
  }

  /**
   * utilizes the current implementation of numerot backend
   * @param user
   * @returns
   */
  public async loadReportNavigation(user: SiteUser, language: string): Promise<ReportingNavigation> {
    if (!user.customerId) return Promise.reject();
    try {
      if (!authenticationModule.isAuthenticated() || !this.auth.get(user).hasReportingAccess) {
        return { workspaces: [] };
      }
      const languageCode = language as LanguageCode;
      const principal = this.getImpersonatedUserId;
      const endpointUrl = `${getBackendUrl()}/api/v1/reports/${user.customerId}/ReportNavigation`;
      const response = await axios.get(endpointUrl, {
        params: { languageCode, principal },
        responseType: 'json',
      });

      return response.data as ReportingNavigation;
    } catch (err) {
      console.error(err);
      return null;
    }
  }

  /**
   * utilizes the current implementation of numerot backend
   * @param workspace
   * @param report
   * @param user
   * @returns
   */
  public async loadReportToken(workspace: string, report: string, user: SiteUser, isDemo: boolean) {
    if (!user.customerId) return Promise.reject();
    try {
      if (!authenticationModule.isAuthenticated() || !this.auth.get(user).hasReportingAccess) {
        return {};
      }
      const principal = this.getImpersonatedUserId;
      const endpointUrl = `${getBackendUrl()}/api/v1/reports/${user.customerId}/ReportEmbedToken/workspace/${workspace}/report/${report}`;
      const isDemoReport = !!isDemo;
      const response = await axios.get(endpointUrl, {
        responseType: 'json',
        params: { principal, isDemoReport },
      });

      return response.data;
    } catch (err) {
      console.error(err);
      return undefined;
    }
  }

  public async showImpersonationInfo(user: SiteUser): Promise<boolean> {
    if (!user.customerId) return Promise.reject();
    try {
      const endpointUrl = `${getBackendUrl()}/api/v1/reports/${user.customerId}/CanImpersonate`;
      const response = await axios.get(endpointUrl, {
        responseType: 'json',
      });

      return (response.data as boolean) && !!this.getImpersonatedUserId;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  public async demoReportUserTracking(user: SiteUser, action: DemoReportUserTrackingAction) {
    const endpointUrl = `${getBackendUrl()}/api/v1/reports/${user.customerId}/DemoReportUserTracking?action=${action}`;
    axios.post(endpointUrl);
  }
}

export const reportServiceApi = new ReportServiceApi();
