import merge from 'lodash/merge';
import { createTheme, darken, responsiveFontSizes, ThemeOptions, Theme } from '@mui/material/styles';
import type { Direction } from '@mui/material';
import { THEMES } from '../constants';
import { darkShadows, lightShadows } from './shadows';
import i18n from 'i18next';
import { enUS, fiFI, Localization } from '@mui/material/locale';

declare module '@mui/material/styles' {
  interface Theme {
    loginPage?: {
      background?: string;
      logo?: string;
    }
  }
  interface ThemeOptions {
    loginPage?: {
      background?: string;
      logo?: string;
    }
  }
}
interface ThemeConfig {
  direction?: Direction;
  responsiveFontSizes?: boolean;
  roundedCorners?: boolean;
  theme?: string;
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    jumbo: true;
    light: true;
    skinny: true;
    transparent: true;
    dark: true;
  }
}

declare module '@mui/material/Badge' {
  interface BadgePropsVariantOverrides {
    landing: true;
  }
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    formFieldHeader: React.CSSProperties;
    tableHead: React.CSSProperties;
    h7: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    formFieldHeader?: React.CSSProperties;
    tableHead?: React.CSSProperties;
    h7?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    formFieldHeader: true;
    tableHead: true;
    h7: true;
  }
}

// Setup more Palette options
declare module '@mui/material/styles' {
  interface PaletteColor {
    integrataRed?: string;
    integrataDarkRed?: string;
    integrataDarkGrey?: string;
    integrataBlack?: string;
    paperHighlight?: string;
    integrataFeatherGrey?: string;
    integrataLightGrey?: string;
    navigationBackground?: string;
    cardBackground?: string;
    mainButton?: string;
    tableBorderColor?: string;
    messageBox?: string;
    messageBoxInternal?: string;
    sidebarText?: string;
  }
  interface SimplePaletteColorOptions {
    integrataRed?: string;
    integrataDarkRed?: string;
    integrataDarkGrey?: string;
    integrataBlack?: string;
    paperHighlight?: string;
    integrataFeatherGrey?: string;
    integrataLightGrey?: string;
    navigationBackground?: string;
    cardBackground?: string;
    mainButton?: string;
    tableBorderColor?: string;
    messageBox?: string;
    messageBoxInternal?: string;
    sidebarText?: string;
  }
}

// Official Integrata colors from Brand Book
const integrataRedColor = '#C70C0E';
const integrataDarkGreyColor = '#353238';
const integrataBlackColor = '#000000';
const integrataDarkRedColor = '#7F1923';
const integrataFeatherGreyColor = '#F5F5F5';
const integrataLightGreyColor = '#CCCCCC';
const tableBorderColor = 'rgba(145, 158, 171, 0.24)';

const baseOptions: ThemeOptions = {
  loginPage: {
    background: 'linear-gradient(147deg, #4d4855 0%, #000000 74%)',
    logo: '/static/HumHumHRLogo-Pysty-2.png'
  },
  direction: 'ltr',
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingTop: 80
        }
      }
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          formFieldHeader: 'p'
        }
      }
    },
    MuiAvatar: {
      styleOverrides: {
        fallback: {
          height: '75%',
          width: '75%'
        }
      }
    },
    MuiBadge: {
      variants: [{
        props: {
          variant: 'landing'
        },
        style: {}
      }]
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'uppercase',
          fontFamily: 'Mynor',
          fontWeight: 'bold'
        }
      },
      variants: [{
        props: {
          variant: 'jumbo'
        },
        style: {
          background: '#e4e4e4',
          borderRadius: 8,
          padding: '20px 30px',
          textTransform: 'none',
          fontSize: '1rem',
          color: 'inherit',
          whiteSpace: 'nowrap',
          ':hover': {
            background: darken('#e4e4e4', 0.1)
          }
        }
      }, {
        props: {
          variant: 'light'
        },
        style: {
          color: '#858585',
          border: '1px solid #d7d7d7',
          background: '#fff'
        }
      }]
    },
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          boxSizing: 'border-box'
        },
        html: {
          MozOsxFontSmoothing: 'grayscale',
          WebkitFontSmoothing: 'antialiased',
          height: '100%',
          width: '100%',
        },
        body: {
          height: '100%',
        },
        '#root': {
          height: '100%',
        },
        '#nprogress .bar': {
          zIndex: '2000 !important'
        }
      }
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: 'h6'
        }
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          overflow: 'hidden'
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 'auto',
          marginRight: '16px'
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none'
        }
      }
    }
  },
  typography: {
    button: {
      textTransform: 'uppercase',
      fontFamily: 'Mynor',
      fontWeight: 'bold'
    },
    fontFamily: '"Mynor","Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
    h1: {
      fontWeight: 600,
      fontSize: '3.5rem',
      fontFamily: 'Mynor Black'
    },
    h2: {
      fontWeight: 700,
      fontSize: '3rem',
      fontFamily: 'Mynor Black'
    },
    h3: {
      fontWeight: 700,
      fontSize: '2rem',
      fontFamily: 'Mynor Black'
    },
    h4: {
      fontWeight: 600,
      fontSize: '2rem',
      fontFamily: 'Mynor Black'
    },
    h5: {
      fontWeight: 600,
      fontSize: '1.5rem',
      fontFamily: 'Mynor Black'
    },
    h6: {
      fontWeight: 600,
      fontSize: '1.250rem',
      fontFamily: 'Mynor'
    },
    h7: {
      fontWeight: 700,
      fontSize: '1rem',
      fontFamily: 'Mynor'
    },
    overline: {
      fontWeight: 600
    },
    formFieldHeader: {
      fontSize: '1.10rem',
      fontFamily: 'Mynor',
      fontWeight: 600,
    },
    subtitle2: {
      fontSize: '1rem'
    },
    tableHead: {
      fontSize: '1rem'
    }
  }
};

const themesOptions: Record<string, ThemeOptions> = {
  [THEMES.DARK]: {
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: `1px solid ${tableBorderColor}`
          }
        }
      },
      MuiButton: {
        variants: [{
          props: {
            variant: 'jumbo'
          },
          style: {
            background: '#1A1D20',
            borderRadius: 8,
            padding: '20px 30px',
            textTransform: 'none',
            fontSize: '1rem',
            color: integrataLightGreyColor,
            whiteSpace: 'nowrap',
            ':hover': {
              background: '#1A1D20'
            }
          }
        }, {
          props: {
            variant: 'light'
          },
          style: {
            color: '#fff',
            border: '1px solid #fff',
            background: 'transparent'
          }
        }, {
          props: {
            variant: 'skinny'
          },
          style: {
            color: '#fff',
            border: '1px solid #5f5f5f',
            backgroundColor: integrataDarkGreyColor,
            textTransform: 'none',
            fontWeight: 'normal',
            padding: '0 10px'
          }
        }, {
          props: {
            variant: 'dark'
          },
          style: {
            color: '#fff',
            border: 0,
            backgroundColor: '#000',
            textTransform: 'none',
          }
        }, {
          props: {
            variant: 'transparent'
          },
          style: {
            color: 'inherit',
            padding: 0,
            textTransform: 'none',
            fontWeight: 'inherit',
            fontSize: 'inherit'
          }
        }]
      }
    },
    palette: {
      background: {
        default: '#19181b',
        paper: '#0a0a0a'
      },
      divider: 'rgba(145, 158, 171, 0.24)',
      error: {
        contrastText: '#ffffff',
        main: '#f44336'
      },
      mode: 'dark',
      primary: {
        contrastText: '#ffffff',
        main: integrataRedColor,
        integrataRed: integrataRedColor,
        integrataDarkRed: integrataDarkRedColor,
        integrataBlack: integrataBlackColor,
        integrataDarkGrey: integrataDarkGreyColor,
        paperHighlight: '#333',
        integrataFeatherGrey: integrataFeatherGreyColor,
        integrataLightGrey: integrataLightGreyColor,
        cardBackground: '#323134',
        mainButton: '#4DA48A',
        tableBorderColor,
        messageBox: integrataFeatherGreyColor,
        messageBoxInternal: '#0a0a0a',
        sidebarText: '#ffffff'
      },
      success: {
        contrastText: '#ffffff',
        main: '#4DA48A'
      },
      text: {
        primary: '#ffffff',
        secondary: '#b6b6b6'
      },
      warning: {
        contrastText: '#ffffff',
        main: '#ff9800'
      }
    },
    shadows: darkShadows
  },
  [THEMES.LIGHT]: {
    loginPage: {
      background: 'linear-gradient(147deg, #FFC0BF 0%, #DACBE6 74%)',
    },
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: `1px solid ${tableBorderColor}`
          }
        }
      },
      MuiBadge: {
        styleOverrides: {
          badge: {
            color: '#fff',
          }
        },
        variants: [{
          props: {
            variant: 'landing'
          },
          style: {
            '.MuiBadge-badge': {
              border: '1px solid #fff'
            },
          }
        }]
      },
      MuiButton: {
        variants: [{
          props: {
            variant: 'jumbo'
          },
          style: {
            background: integrataRedColor,
            borderRadius: 8,
            padding: '20px 30px',
            textTransform: 'none',
            fontSize: '1rem',
            color: '#FFF9F4',
            whiteSpace: 'nowrap',
            ':hover': {
              background: integrataRedColor
            }
          }
        }, {
          props: {
            variant: 'contained',
            color: 'primary'
          },
          style: {
            background: '#C70C0E',
            color: '#FFF9F4',
            border: 0
          }
        },
        {
          props: {
            variant: 'light'
          },
          style: {
            color: '#16161E',
            border: '1px solid #16161E',
            background: 'transparent'
          }
        }, {
          props: {
            variant: 'skinny'
          },
          style: {
            color: '#16161E',
            border: '1px solid #cecece',
            backgroundColor: '#ececec',
            textTransform: 'none',
            fontWeight: 'normal',
            padding: '0 10px'
          }
        }, {
          props: {
            variant: 'dark'
          },
          style: {
            color: '#fff',
            border: 0,
            backgroundColor: '#16161E',
            textTransform: 'none',
            ':hover': {
              background: '#16161E'
            }
          }
        }, {
          props: {
            variant: 'transparent'
          },
          style: {
            color: 'inherit',
            padding: 0,
            textTransform: 'none',
            fontWeight: 'inherit',
            fontSize: 'inherit'
          }
        }]
      }
    },
    palette: {
      background: {
        default: '#f4f5f7',
        paper: '#fff',
      },
      divider: 'rgba(145, 158, 171, 0.24)',
      error: {
        contrastText: '#000',
        main: '#C70C0E'
      },
      mode: 'light',
      primary: {
        contrastText: '#16161E',
        main: integrataRedColor,
        integrataRed: integrataRedColor,
        integrataDarkRed: integrataDarkRedColor,
        integrataBlack: '#fff',
        integrataDarkGrey: integrataDarkGreyColor,
        paperHighlight: '#333',
        integrataFeatherGrey: integrataFeatherGreyColor,
        integrataLightGrey: integrataLightGreyColor,
        cardBackground: '#fff',
        mainButton: '#4DA48A',
        tableBorderColor,
        messageBox: '#fff',
        messageBoxInternal: '#DACBE6',
        sidebarText: '#16161E'
      },
      success: {
        contrastText: '#ffffff',
        main: '#4DA48A'
      },
      text: {
        primary: '#16161E',
        secondary: 'rgba(22,22,30,0.6)'
      },
      warning: {
        contrastText: '#ffffff',
        main: '#ff9800'
      }
    },
    shadows: lightShadows
  },
  [THEMES.ACCOUNTOR]: {
    loginPage: {
      background: 'url(/static/accountor_login_bg.png)',
      logo: '/static/mepco_analytiikka_black.png'
    },
    typography: {
      fontFamily: 'Inter, Helvetica, Arial',
      h1: {
        fontFamily: 'Fabrik'
      },
      h2: {
        fontFamily: 'Fabrik'
      },
      h3: {
        fontFamily: 'Fabrik'
      },
      h4: {
        fontFamily: 'Fabrik'
      },
      h5: {
        fontFamily: 'Fabrik'
      },
      h6: {
        fontFamily: 'Fabrik'
      },
      formFieldHeader: {
        fontFamily: 'Inter'
      }
    },
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: '1px solid rgba(145, 158, 171, 0.24)'
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontFamily: 'Inter, Helvetica, Arial'
          }
        },
        variants: [{
          props: {
            variant: 'contained',
            color: 'primary'
          },
          style: {
            background: '#005050',
            color: '#FFF9F4',
            border: 0,
            padding: '6px 16px',
            borderRadius: 16,
            ':hover': {
              background: darken('#005050', 0.3)
            }
          }
        }, {
          props: {
            variant: 'skinny'
          },
          style: {
            color: '#16161E',
            border: '1px solid #cecece',
            backgroundColor: '#ececec',
            textTransform: 'none',
            fontWeight: 'normal',
            padding: '0 10px',
            fontSize: 14
          }
        }, {
          props: {
            variant: 'dark'
          },
          style: {
            color: '#fff',
            border: 0,
            backgroundColor: '#16161E',
            textTransform: 'none',
            ':hover': {
              background: '#16161E'
            }
          }
        },
        {
          props: {
            variant: 'light'
          },
          style: {
            color: '#005050',
            border: '1px solid #005050',
            background: 'transparent'
          }
        }, {
          props: {
            variant: 'transparent'
          },
          style: {
            color: 'inherit',
            padding: 0,
            textTransform: 'none',
            fontWeight: 'inherit',
            fontSize: 'inherit'
          }
        }]
      }
    },
    palette: {
      background: {
        default: '#f4f5f7',
        paper: '#fff',
      },
      divider: 'rgba(145, 158, 171, 0.24)',
      error: {
        contrastText: '#ffffff',
        main: '#f44336'
      },
      mode: 'light',
      primary: {
        contrastText: '#000',
        main: '#F75759',
        integrataRed: integrataRedColor,
        integrataDarkRed: integrataDarkRedColor,
        integrataBlack: '#fff',
        integrataDarkGrey: integrataDarkGreyColor,
        paperHighlight: '#333',
        integrataFeatherGrey: integrataFeatherGreyColor,
        integrataLightGrey: integrataLightGreyColor,
        navigationBackground: '#005050',
        cardBackground: '#fff',
        mainButton: '#4DA48A',
        tableBorderColor,
        messageBox: '#fff',
        messageBoxInternal: '#d3ecec',
        sidebarText: '#fff'
      },
      success: {
        contrastText: '#ffffff',
        main: '#4DA48A'
      },
      text: {
        primary: '#005050',
        secondary: '#8c8c8c'
      },
      warning: {
        contrastText: '#ffffff',
        main: '#ff9800'
      }
    },
    shadows: lightShadows
  }
};

export const createCustomTheme = (config: ThemeConfig = {}): Theme => {
  const themeOptions = themesOptions[config.theme];
  const getCurrentLanguage = (): Localization => {
    switch (i18n.language) {
      case 'fi':
        return fiFI;
      default:
        return enUS;
    }
  };

  let theme = createTheme(merge(
    {},
    baseOptions,
    themeOptions,
    {
      ...(
        config.roundedCorners && {
          shape: {
            borderRadius: 16
          }
        }
      )
    },
    {
      direction: config.direction
    }
  ), getCurrentLanguage());

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
