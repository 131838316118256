import { CircularProgress, FormHelperText, Grid, MenuItem, Select } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { MessageThreadRelatedItemDto, MessageThreadRelatedItemOptionsDto, MessageThreadRelatedItemWithName } from 'src/types/apiSchemas';
import { baseApi } from 'src/API/baseApi';
import toast from 'react-hot-toast';

enum MessageThreadRelatedItemType {
  NoRelatedItem = 'NoRelatedItem',
  Company = 'Company',
  Person = 'Person'
}

interface MessageThreadRelatedItemChangeProps {
  handleRelatedItemChange: (relatedItem: MessageThreadRelatedItemDto) => void
  handleDisableSaveButton?: (disable: boolean) => void
  currentRelatedItem?: MessageThreadRelatedItemWithName
  selectedCustomer: number;
  selectedRelatedItemFromPersonSelection?: MessageThreadRelatedItemDto;
  toBeModifiedRelatedItemType?: MessageThreadRelatedItemType;
  hasError?: boolean;
}

const MessageThreadRelatedCompanyChange: FC<MessageThreadRelatedItemChangeProps> = observer(({ hasError, handleRelatedItemChange, currentRelatedItem, selectedCustomer, handleDisableSaveButton, selectedRelatedItemFromPersonSelection, toBeModifiedRelatedItemType }) => {
  const { t } = useTranslation();

  const [messageThreadRelatedItemOptions, setMessageThreadRelatedItemOptions] = useState<MessageThreadRelatedItemOptionsDto>(null);
  const [messageThreadRelatedItemOptionsLoading, setMessageThreadRelatedItemOptionsLoading] = useState<boolean>(true);
  const [messageThreadRelatedCompanyName, setMessageThreadRelatedCompanyName] = useState<string>(currentRelatedItem?.relatedCompanyName ?? '');
  const [selectedRelatedItem, setSelectedRelatedItem] = useState<MessageThreadRelatedItemDto>(selectedRelatedItemFromPersonSelection ?? { personUserId: null, companyId: null });

  const getMessageThreadRelatedItemOptions = async () => {
    setMessageThreadRelatedItemOptionsLoading(true);
    try {
      const response = await baseApi.getMessageThreadRelatedItemOptions(selectedCustomer, '');
      setMessageThreadRelatedItemOptions(response);
    } catch (err) {
      toast.error(t('CouldNotRetrieveMessageThreadRelatedItemOptionsToast'));
      console.log(err);
    }
    setMessageThreadRelatedItemOptionsLoading(false);
  };

  useEffect(() => {
    if (selectedCustomer !== 0) {
      if (!currentRelatedItem) {
        setMessageThreadRelatedCompanyName('');
        setSelectedRelatedItem({ personUserId: null, companyId: null });
      }
      
      setMessageThreadRelatedItemOptions(null);
      getMessageThreadRelatedItemOptions();
    }
  }, [selectedCustomer]);

  useEffect(() => {
    if (selectedRelatedItemFromPersonSelection?.companyId && !(toBeModifiedRelatedItemType == MessageThreadRelatedItemType.Company)) {
      const newCompanyName = messageThreadRelatedItemOptions.companies.find(c => c.id === selectedRelatedItemFromPersonSelection?.companyId)?.name;
      setMessageThreadRelatedCompanyName(newCompanyName);
    }
  }, [selectedRelatedItemFromPersonSelection]);

  return (
    <>
      <Grid
        item
        xs={12}
      >
          <Grid
            item
            xs={12}
            flexDirection='row'
          >
            {messageThreadRelatedItemOptions && (
              <Select
              error={hasError}
              value={messageThreadRelatedCompanyName}
              renderValue={(selected) => {
                if (selectedRelatedItemFromPersonSelection?.companyId && !(toBeModifiedRelatedItemType == MessageThreadRelatedItemType.Company)) {
                  return messageThreadRelatedItemOptions?.companies.find(c => c.id === selectedRelatedItemFromPersonSelection?.companyId)?.name;
                }
                if (selected !== t('MessageThreadRelatedCompanyOptionsNoRelatedCompany') && selectedRelatedItem?.companyId === null && currentRelatedItem?.companyId !== null) {
                  return currentRelatedItem?.relatedCompanyName;
                }
                
                return selected;
              }}
              name="relatedCompany"
              disabled={messageThreadRelatedItemOptionsLoading}
              onChange={(e) => {
                const company = messageThreadRelatedItemOptions.companies.find((c) => c.name === e.target.value);
                const relatedItem = {
                  companyId: company?.id ?? null,
                  personUserId: currentRelatedItem?.person?.id
                };
                if (toBeModifiedRelatedItemType == MessageThreadRelatedItemType.Company)
                  handleDisableSaveButton(currentRelatedItem?.companyId == relatedItem?.companyId && currentRelatedItem?.companyId == relatedItem?.companyId);
                setMessageThreadRelatedCompanyName(company ? company?.name : t('MessageThreadRelatedCompanyOptionsNoRelatedCompany'));
                handleRelatedItemChange(relatedItem);
                setSelectedRelatedItem(relatedItem);
              } }
              sx={{ width: 300, maxWidth: '100%' }}
              >
              <MenuItem value={t('MessageThreadRelatedCompanyOptionsNoRelatedCompany')}>{t('MessageThreadRelatedCompanyOptionsNoRelatedCompany')}</MenuItem>
              {messageThreadRelatedItemOptions?.companies?.map((c) => (
                <MenuItem value={c.name} key={c.id}>
                  {c.name}
                </MenuItem>
              ))}
              </Select>
            )}
            {hasError && <FormHelperText error>{t('YupGeneralMessage')}</FormHelperText>}
            {messageThreadRelatedItemOptionsLoading && (
              <CircularProgress
                size={20}
                sx={{ ml: 2 }}
              />
            )}
          </Grid>
      </Grid>
    </>
  );
});

export default MessageThreadRelatedCompanyChange;