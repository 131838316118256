import { FC, useState } from 'react';
import { Box, Button, CircularProgress, Grid, Switch, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import MicrosoftIcon from '../../../icons/Microsoft';
import { useTranslation } from 'react-i18next';
import { AccountInfo } from '@azure/msal-browser';
import toast from 'react-hot-toast';
import { baseApi } from 'src/API/baseApi';
import authenticationModule from 'src/azure/azure-authentication-context';
import axios from 'axios';
import { useAppStore } from 'src/store/mobx/appStore';
import { observer } from 'mobx-react';
import { AuthStorageEnum, IAuthConfigStorageService, AuthConfigStorageService } from 'src/Services';
import { externalIdConfigured } from 'src/utils/loginUtils';
import { MSAL_CONFIG } from 'src/azure/azure-authentication-config';
import { MSAL_EXTERNAL_CONFIG } from 'src/azure/external-authentication-config';
import ButtonDropdown from 'src/components/common/ButtonDropdown';
import InfoPopover from 'src/components/common/InfoPopover';
import { ILoginInfoUpdate, LoginInfo, RefreshOperation } from 'src/utils/LoginInfoUtil';

const MSALLogin: FC = observer(() => {
  enum EmailAction {
    Login = 'Login',
    Register = 'Register'
  }
  const { t } = useTranslation();
  const appStore = useAppStore();
  const authConfigStorageService: IAuthConfigStorageService = new AuthConfigStorageService();
  const [persistAuth, setPersistAuth] = useState({ checked: authConfigStorageService.msalConfig?.cache?.cacheLocation === AuthStorageEnum.localStorage, });
  const [emailLoginAction, setEmailLoginAction] = useState<EmailAction>(EmailAction.Login);
  const loginInfo = new LoginInfo(appStore);

  const loginButtonBgColor: string = '#2f2f2f';
  const loginButtonColor: string = '#FFFFFF';
  const loginButtonHover: string = '#9e9e9e';

  const MicrosoftButton = withStyles({
    root: {
      borderRadius: 0,
      paddingLeft: 20,
      paddingRight: 20,
      backgroundColor: loginButtonBgColor,
      color: loginButtonColor,
      '&:hover': {
        backgroundColor: loginButtonBgColor,
        color: loginButtonHover,
      },
    }
  })(Button);

  const handleMsalCallback = async (accountInfo: AccountInfo, accessToken: string) => {
    try {
      authenticationModule.setActiveAccount(accountInfo);
      // make sure to indicate logging in process in UI when retrieving user info against backend
      appStore.loginStore.beginLogin();

      const userDto = await baseApi.login(accessToken);

      // Fetch user picture from their Graph API
      const userPicture: string = userDto.profilePictureUrl;
      if (userPicture === null) {
        let userPictureFile: File = null;
        try {
          userPictureFile = await authenticationModule.GetUserPicture();
        } catch (err) {
          console.error('Could not retrieve used profile picture from Microsoft Graph API');
        }
        if (userPictureFile) {
          try {
            const updatedProfilePicture = await baseApi.patchUserProfilePicture(userPictureFile, accessToken);
            userDto.profilePictureUrl = updatedProfilePicture.profilePictureUrl;
          } catch (err) {
            console.error(`Updating profile picture failed ${err}`);
          }
        }
      }

      // refreshing the resolved data to user related objects (login, settings) in store
      loginInfo.updateUser({ userDto, operation: RefreshOperation.initialize } as ILoginInfoUpdate);
    } catch (err) {
      // Check if the error was thrown from axios
      let errorMessage = t('LoginErrorToast');
      if (axios.isAxiosError(err)) {
        switch (err.response?.status) {
          case 404:
            errorMessage = errorMessage.concat(': ', t('UserNotFound'));
            break;
          default:
            break;
        }
      } else {
        errorMessage = errorMessage.concat(': ', err.message);
      }

      toast.error(errorMessage, { duration: 3000, });
      console.error(err);
      appStore.loginStore.logout();
    }
  };

  const authErrorsToNotPrompt = ['user_cancelled', 'interaction_in_progress'];

  const handleMsalError = (error: any) => {
    try {
      if (!error || authErrorsToNotPrompt.some((noPrompt: string) => error.toString().includes(noPrompt))) {
        return;
      }

      const correlationIdRegex = /Correlation ID: ([\w-]+)/;
      const match = error.toString().match(correlationIdRegex);

      let correlationId = 'N/A';
      if (match && match.length > 1) {
        // eslint-disable-next-line prefer-destructuring
        correlationId = match[1];
      }

      toast.error(
        <div>
          <div>{`${t('LoginFailedMessage1')}`}</div>
          <br />
          <div>{`${t('LoginFailedMessage2')}`}</div>
          <div>
            <br />
            {`${correlationId}`}
          </div>
        </div>,
        { duration: 30000, }
      );

      console.error(error.message);
      appStore.loginStore.logout();
    } catch (err) {
      console.error(err);
    }
  };

  const loginAction = async (prompt: string = 'select_account'): Promise<void> => {
    try {
      authenticationModule.login(handleMsalCallback, handleMsalError, prompt);
    } catch (err) {
      console.error(err);
    }
  };

  const signUpAction = async (): Promise<void> => {
    try {
      authenticationModule.signUp(handleMsalCallback, handleMsalError);
    } catch (err) {
      console.error(err);
    }
  };

  const handleEmailAction = (action: EmailAction) => {
    authenticationModule.setConfigAndInit(MSAL_EXTERNAL_CONFIG);
    switch (action) {
      case EmailAction.Register:
        signUpAction();
        break;
      default:
        loginAction();
    }
  };

  const handleItemClick = (value: EmailAction) => {
    if (value == emailLoginAction) {
      handleEmailAction(value);
    }
  };

  const loadingSpinner = (
    <Box
      display="flex"
      justifyContent="center"
      sx={{ mt: 10 }}
      bgcolor="background.secondary"
    >
      <CircularProgress
        size={65}
      />
    </Box>
  );

  const handlePersistAuth = (event) => {
    // toggle local state value
    setPersistAuth({ checked: event.target.checked });
    // toggle storage service authStorage location
    const storageForAuth = event.target.checked ? AuthStorageEnum.localStorage : AuthStorageEnum.sessionStorage;
    authConfigStorageService.msalConfig = { ...authConfigStorageService.msalConfig, cache: { ...authConfigStorageService.msalConfig.cache, cacheLocation: storageForAuth } };
    // toggle authentication module to use selected storage (localStorage or sessionStorage)
    authenticationModule.setAndInitCacheConfig(storageForAuth);
    // toggle login store to use selected storage (localStorage or sessionStorage)
    appStore.loginStore.reset();
  };

  const loginButton = (
    <Box>
      <Grid
        container
      >
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            paddingBottom: '30px'
          }}
        >
          <MicrosoftButton
            size="large"
            type="submit"
            startIcon={<MicrosoftIcon />}
            onClick={() => {
              authenticationModule.setConfigAndInit(MSAL_CONFIG);
              loginAction();
            }}
          >
            {t('LoginPageSignInWithMicrosoftButtonTitle')}
          </MicrosoftButton>
          {externalIdConfigured && (
            <InfoPopover text={t('MicrosoftAuthenticationDescription')} />
          )}
        </Grid>
        {externalIdConfigured && (
          <>
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                paddingBottom: '30px'
              }}
            >
              <ButtonDropdown
                value={emailLoginAction}
                options={[
                  { label: t('LoginWithEmailButtonLabel'), value: EmailAction.Login },
                  { label: t('SignUpPersonalAccountButtonCaption'), value: EmailAction.Register },
                ]}
                onClick={() => {
                  handleEmailAction(emailLoginAction);
                }}
                onChange={(e) => {
                  setEmailLoginAction(e.target.value as EmailAction);
                  handleEmailAction(e.target.value as EmailAction);
                }}
                disabled={false}
                padding={10}
                style={{
                  fontSize: '15px',
                  backgroundColor: loginButtonBgColor,
                  color: loginButtonColor,
                  borderRadius: '0px !important',
                  ':hover': {
                    backgroundColor: loginButtonBgColor,
                    color: loginButtonHover,
                  },
                  '&.Mui-focused': {
                    backgroundColor: loginButtonBgColor,
                  },
                }}
                dropDownStyles={{
                  borderRadius: '0px',
                  backgroundColor: loginButtonBgColor,
                  '.Mui-selected, .Mui-selected:hover': {
                    backgroundColor: loginButtonBgColor,
                    color: loginButtonColor
                  },
                  color: loginButtonColor
                }}
                handleStyles={{
                  '> div': {
                    'border-top-right-radius': '0px',
                    'border-bottom-right-radius': '0px',
                    color: loginButtonColor
                  },
                  color: loginButtonColor,
                }}
                onItemClick={handleItemClick}
              />
              <InfoPopover text={t('ExternalAuthenticationDescription')} />
            </Grid>
          </>
        )}
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Typography
            color="textPrimary"
            variant="caption"
          >
            <Switch
              checked={persistAuth.checked}
              onChange={handlePersistAuth}
              size="medium"
            />
            {t('LoginPageRememberLogin')}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );

  return appStore?.loginStore?.get()?.isAuthenticating ? loadingSpinner : loginButton;
});

export default MSALLogin;
