import { useEffect, useState } from 'react';
import type { FC, ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import { experimentalStyled } from '@mui/material/styles';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import useMediaQuery from '@mui/material/useMediaQuery';
import type { Theme } from '@mui/material';
import useVersionCheck from 'src/hooks/useVersionCheck';
import CommonDialog from '../common/CommonDialog';
import { t } from 'i18next';
import { Tooltip, IconButton } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

interface DashboardLayoutProps {
  children?: ReactNode;
}

const DashboardLayoutRoot = experimentalStyled('div')(
  ({ theme }) => (
    {
      backgroundColor: theme.palette.background.default,
      display: 'flex',
      height: '100%',
      overflow: 'hidden',
      width: '100%'
    }
  )
);

const DashboardLayoutContainer = experimentalStyled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden'
});

const DashboardLayoutContent = experimentalStyled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto',
  position: 'relative',
  WebkitOverflowScrolling: 'touch'
});

const DashboardLayoutWrapper = experimentalStyled('div')(
  ({ theme }) => (
    {
      display: 'flex',
      flex: '1 1 auto',
      overflow: 'hidden',
      transition: 'padding 200ms',
      [theme.breakpoints.down('lg')]: {
        paddingTop: '64px'
      },
      [theme.breakpoints.up('lg')]: {
        paddingLeft: '280px'
      }
    }
  )
);

const DashboardLayout: FC<DashboardLayoutProps> = () => {
  const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState<boolean>(false);
  const [isSidebarDesktopOpen, setIsSidebarDesktopOpen] = useState<boolean>(true);
  const [showNewVersionAvailable, setShowNewVersionAvailable] = useState<boolean>(false);
  const { newVersion } = useVersionCheck();
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

  const toggleDesktopNavigation = () => setIsSidebarDesktopOpen((val) => !val);

  const getLayoutPadding = () => {
    if (lgUp) {
      return isSidebarDesktopOpen ? 280 : 70;
    }

    return 0;
  };

  useEffect(() => {
    if (newVersion) {
      setShowNewVersionAvailable(true);
    }
  }, [newVersion]);

  return (
    <DashboardLayoutRoot>
      <DashboardNavbar
        onSidebarMobileOpen={(): void => setIsSidebarMobileOpen((val) => !val)}
      />
      <DashboardSidebar
        onMobileClose={(): void => setIsSidebarMobileOpen(false)}
        openMobile={isSidebarMobileOpen}
        toggleDesktopNavigation={toggleDesktopNavigation}
        sidebarDesktopOpen={isSidebarDesktopOpen}
      />
      <DashboardLayoutWrapper style={{ paddingLeft: getLayoutPadding() }}>
        <DashboardLayoutContainer>
          {/* NOTE: the dashboardLayoutContent is the scrollable element in the dashboard */}
          <DashboardLayoutContent id="dashboardLayoutContent">
            <Outlet />
          </DashboardLayoutContent>
        </DashboardLayoutContainer>
      </DashboardLayoutWrapper>
      {showNewVersionAvailable && (
        <CommonDialog
          isOpen={true}
          dialogTitle={t('NewVersionAvailableTitle')}
          dialogContent={t('NewVersionAvailableMessage')}
          processing={false}
          handleCancel={() => setShowNewVersionAvailable(false)}
          handleConfirm={() => window.location.reload()}
          confirmButtonTitle={t('NewVersionAvailableReload')}
          cancelButtonTitle={t('NewVersionAvailableReloadLater')}
        >
          <Tooltip title={t('NewVersionAvailableTooltip')}>
            <IconButton size="small">
              <InfoIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </CommonDialog>
      )}
    </DashboardLayoutRoot>
  );
};

export default DashboardLayout;
