import { AccessPermission, CustomerAccessPermissionDto, InternalRole, UserRole } from 'src/types/apiSchemas';
import { SiteUser } from 'src/types/siteUser';

export interface IAuthorizationService {
  has(permission: AccessPermission): AuthorizationService;
  hasCustomerPermission(permission: AccessPermission, accessPermissions: CustomerAccessPermissionDto[], customerId: number): AuthorizationService;
  anyCustomerHas(permission: AccessPermission): AuthorizationService;
  hasAnyInternalRole(customerId: number): AuthorizationService;
  hasAnyUserRole(customerId: number): AuthorizationService;
  hasSpecificInternalRole(role: InternalRole, customerId?: number): AuthorizationService;
  hasManagerOrSubstituteManagerAccess: boolean;
  hasManagerAccess: boolean;
  hasSubstituteManagerAccess: boolean;
  isPaymentApproverOrReviewer: boolean;
  isMessagingConsultant: boolean;
  isMainUser: boolean;
  get(user: SiteUser): AuthorizationService;
  verify(): boolean;
}

/**
 * fluent service for checking user permissions
 * samples:
 * const auth = new AuthorizationService(user);
 * const isManager = auth.get().has(AccessPermission.ManagerAccess).any();
 * const attachmentsOrMessaging = this.has(AccessPermission.AttachmentsAccess).has(AccessPermission.MessagingAccess).any();
 */
export class AuthorizationService implements IAuthorizationService {
  private user: SiteUser = null;
  private authorizations: boolean[] = [];

  constructor(user?: SiteUser) {
    this.user = user;
  }

  public get = (user: SiteUser) => (new AuthorizationService(user));

  public hasCustomerPermission = (permission: AccessPermission, accessPermissions: CustomerAccessPermissionDto[], customerId: number): AuthorizationService => {
    const permissionsToCheck = accessPermissions.find((p) => p.customerId === customerId);

    if (!permissionsToCheck) {
      this.authorizations.push(false);
      return this;
    }
    this.authorizations.push(permissionsToCheck.accessPermissions.includes(permission));
    return this;
  };

  public has = (permission: AccessPermission): AuthorizationService => {
    const permissionsToCheck = this.user?.accessPermissions;
    this.authorizations.push(permissionsToCheck.find((p) => p.customerId === this.user?.customerId)?.accessPermissions.includes(permission));
    return this;
  };

  public anyCustomerHas = (permission: AccessPermission): AuthorizationService => {
    this.authorizations.push(this.user?.accessPermissions?.some((p) => p.accessPermissions.includes(permission)));
    return this;
  };

  public is = (role: UserRole, customerId?: number): AuthorizationService => {
    this.authorizations.push(this.user?.customerUserRoles?.some((r) => r.customerId === (customerId ?? this.user.customerId) && r.userRoles.includes(role)));
    return this;
  };

  public hasSpecificInternalRole = (role: InternalRole, customerId?: number): AuthorizationService => {
    this.authorizations.push(this.user?.customerInternalRoles?.some((r) => (r.customerId === null || r.customerId === customerId) && r.internalRoles.includes(role)));
    return this;
  };

  public hasAnyInternalRole(customerId: number): AuthorizationService {
    this.authorizations.push(this.user?.customerInternalRoles?.some((r) => (r.customerId === null || r.customerId === customerId) && r.internalRoles.length > 0));
    return this;
  }

  public hasAnyUserRole(customerId: number): AuthorizationService {
    this.authorizations.push(this.user?.customerUserRoles?.some((r) => (r.customerId === customerId) && r.userRoles.length > 0));
    return this;
  }

  public get hasReportingAccess(): boolean {
    return this.has(AccessPermission.Reporting).verify();
  }

  public get hasManagerOrSubstituteManagerAccess(): boolean {
    return this.hasManagerAccess || this.hasSubstituteManagerAccess;
  }

  public get hasManagerAccess(): boolean {
    return this.has(AccessPermission.ManagerAccess).verify();
  }

  public get hasSubstituteManagerAccess(): boolean {
    return this.has(AccessPermission.SubstituteManagerAccess).verify();
  }

  public get hasHolidayBonusChangeAccess(): boolean {
    return this.has(AccessPermission.HolidayBonusChangesAccess).verify();
  }

  public get isHR(): boolean {
    return this.is(UserRole.HR).verify();
  }

  public get isPayroll(): boolean {
    return this.hasSpecificInternalRole(InternalRole.Payroll, this.user.customerId).verify();
  }

  public get isPaymentApproverOrReviewer(): boolean {
    return this.has(AccessPermission.PaymentsApprover).has(AccessPermission.PaymentsReviewer).verify();
  }

  public get isMessagingConsultant(): boolean {
    return this.anyCustomerHas(AccessPermission.MessagingAccess).verify() && this.user?.isConsultantUser;
  }

  public get isMainUser(): boolean {
    return this.is(UserRole.MainUser).verify();
  }

  public get hasHRApprovalsAccess(): boolean {
    return this.has(AccessPermission.HRApprovalsAccess).verify();
  }

  public verify = (): boolean => (this.authorizations.some((value: boolean) => value === true));
}
