import { useCallback, useState, useEffect } from 'react';
import type { ChangeEvent, FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Link,
  Breadcrumbs,
  CircularProgress,
  Container,
  Grid,
  Tab,
  Tabs,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  ToggleButton,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { ManagerApprovalsDto, ManagerApprovalStatus } from 'src/types/apiSchemas';
import { useTranslation } from 'react-i18next';
import useMounted from 'src/hooks/useMounted';
import { managerApprovalsApi } from 'src/API/managerApprovalsApi';
import { Masonry } from '@mui/lab';
import ApprovalCard from 'src/components/dashboard/approvals/ApprovalCard';
import { useAppStore } from 'src/store/mobx/appStore';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router';
import { AuthorizationService, IAuthorizationService } from 'src/Services';
import ChevronRightIcon from '../../icons/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ManagerSubstitutionAlert from 'src/components/common/ManagerSubstitutionAlert';
import toast from 'react-hot-toast';

// ToDo: deprecate this component, since transferred to using the new Approvals component using Approvals API
const ApprovalsDeprecated: FC = observer(() => {
  const [approvals, setApprovals] = useState<ManagerApprovalsDto[]>([]);
  const [expanded, setExpanded] = useState<string | false>('panel0');
  const appStore = useAppStore();
  const { user } = appStore.loginStore.get();
  const [isLoading, setIsLoading] = useState(true);
  const mounted = useMounted();
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState<ManagerApprovalStatus>('Pending');
  const tabs = [
    { label: t('PendingApprovalsTabTitle'), value: 'Pending' },
    { label: t('ApprovedApprovalsTabTitle'), value: 'Approved' },
    { label: t('DeclinedApprovalsTabTitle'), value: 'Declined' }
  ];
  const navigate = useNavigate();
  const auth: IAuthorizationService = new AuthorizationService();
  const [selectedFilter, setSelectedFilter] = useState('all');
  const [multiselect, setMultiselect] = useState(false);
  const [checkboxStates, setCheckboxStates] = useState([]);

  const changeFilter = (event) => {
    if (event.target.value !== 'holidayBonusChangeRequest') setMultiselect(false);
    setSelectedFilter(event.target.value);
  };

  const handleCheckboxChange = (cardId, isChecked) => {
    setCheckboxStates((prevState) => {
      let newState;

      if (isChecked) {
        if (!prevState.includes(cardId)) {
          newState = [...prevState, cardId];
        } else {
          newState = prevState;
        }
      } else {
        newState = prevState.filter((id) => id !== cardId);
      }
      return newState;
    });
  };

  const filterApproval = (allApprovals: ManagerApprovalsDto) => {
    const filteredApprovals = allApprovals.managerApprovals.filter((approval) => {
      if (selectedFilter === 'all') return true;
      if (selectedFilter === 'absence') return approval.absence !== null;
      if (selectedFilter === 'attachment') return approval.absence === null && approval.attachment !== null;
      if (selectedFilter === 'holidayBonusChangeRequest') return approval.holidayBonusChangeRequest !== null;
      return false;
    });

    const newApprovals: ManagerApprovalsDto = {
      manager: allApprovals.manager,
      managerApprovals: filteredApprovals
    };

    return newApprovals;
  };

  const selectAll = () => {
    approvals.forEach((managerApprovals) => {
      const filteredApprovals = filterApproval(managerApprovals);
      filteredApprovals.managerApprovals.forEach((approval) => {
        if (approval.holidayBonusChangeRequest !== null) handleCheckboxChange(approval.holidayBonusChangeRequest.holidayBonusChangeRequestId, true);
      });
    });
  };

  if (!auth.get(user).hasManagerOrSubstituteManagerAccess && (!auth.get(user).isHR && !auth.get(user).hasHolidayBonusChangeAccess)) {
    navigate('/401');
  }

  const handleTabsChange = (event: ChangeEvent<{}>, value: string): void => {
    if (value !== 'Pending') setMultiselect(false);
    setCurrentTab(value as ManagerApprovalStatus);
  };

  const getMyApprovals = useCallback(async () => {
    if (mounted.current) {
      setIsLoading(true);
    }
    try {
      const approvaltData = await managerApprovalsApi.getMyApprovalsDeprecated(user, currentTab);
      if (mounted.current) {
        setApprovals(approvaltData);
      }
    } catch (e) {
      console.log(e);
    } finally {
      if (mounted.current) {
        setIsLoading(false);
      }
    }
  }, [currentTab]);

  useEffect(() => {
    getMyApprovals();
  }, [currentTab]);

  const approveSelected = async () => {
    try {
      await managerApprovalsApi.approveMultiHolidayBonusChangeRequests(user.userId, checkboxStates);
      getMyApprovals();
      appStore.notificationStore.refreshApprovalNotifications(user);
      setCheckboxStates([]);
      toast.success(t('ApprovalsSaveSuccessful'));
    } catch (err) {
      console.log(err);
      toast.error(t('ApprovalsSaveFailed'));
    }
  };

  const handleAccordionToggle = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  const renderApprovals = (allApprovals: ManagerApprovalsDto, index: number): JSX.Element => {
    if (!allApprovals) {
      return null;
    }

    // My subordinates is expanded by default
    const isMySubordinates: boolean = allApprovals.manager?.username === user.email;
    const filteredApprovals = filterApproval(allApprovals);
    const approvalCount = filteredApprovals.managerApprovals?.length ?? 0;

    let accordionSummaryText: string = '';
    switch (currentTab) {
      case 'Pending':
        accordionSummaryText = isMySubordinates ? t('ApprovalsOwnPending') : `${t('ApprovalsSubstitutionManagerPrefix')}: ${allApprovals.manager.name}`;
        break;
      case 'Approved':
        accordionSummaryText = isMySubordinates ? t('ApprovalsOwnApproved') : `${t('ApprovalsSubstitutionManagerPrefix')}: ${allApprovals.manager.name}`;
        break;
      case 'Declined':
        accordionSummaryText = isMySubordinates ? t('ApprovalsOwnDeclined') : `${t('ApprovalsSubstitutionManagerPrefix')}: ${allApprovals.manager.name}`;
        break;
      default:
        break;
    }
    accordionSummaryText += ` (${approvalCount})`;

    return (
      <Box
        key={`approvals-${index}`}
        sx={{ mr: -2, marginBottom: 3 }}
      >
        <Accordion
          expanded={expanded === `panel${index}`}
          onChange={handleAccordionToggle(`panel${index}`)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography
              variant="h5"
            >
              {accordionSummaryText}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Masonry
              columns={{ xs: 1, sm: 1, md: 2, xl: 3 }}
              spacing={2}
              sx={{ marginBottom: 3 }}
            >
              {
                approvalCount > 0 ? filteredApprovals.managerApprovals.map((managerApproval, i) => (
                  <ApprovalCard
                    managerInfo={{ managerName: filteredApprovals.manager?.name }}
                    approval={managerApproval}
                    // eslint-disable-next-line react/no-array-index-key
                    key={i}
                    selectable={multiselect}
                    isChecked={managerApproval.holidayBonusChangeRequest != null ? checkboxStates.includes(managerApproval.holidayBonusChangeRequest.holidayBonusChangeRequestId) : false}
                    onCheckedChange={handleCheckboxChange}
                  />
                ))
                  : (
                    <Box sx={{ mt: 15 }}>
                      <Typography
                        variant="h6"
                      >
                        {t('NothingToSeeHere')}
                      </Typography>
                    </Box>
                  )
              }
            </Masonry>
          </AccordionDetails>
        </Accordion>
      </Box>
    );
  };

  return (
    <>
      <Helmet>
        <title>{t('ApprovalsPageHelmet')}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8,
          mx: 2
        }}
      >
        <Container
          maxWidth="xl"
        >
          <Grid item>
            <Typography
              color="textPrimary"
              variant="h5"
              sx={{ mb: 3 }}
            >
              {t('ApprovalsPageTitle')}
            </Typography>
            <Grid
              container
              direction="row"
            >
              <Breadcrumbs
                sx={{
                  paddingLeft: '1%',
                }}
                aria-label="breadcrumb"
                separator={(
                  <ChevronRightIcon
                    fontSize="small"
                    sx={{ mb: 5 }}
                  />
                )}
              >
                <Grid
                  item
                  sx={{ mb: 5 }}
                >
                  <Link
                    color="textPrimary"
                    component={RouterLink}
                    to="/dashboard/landingpage"
                    variant="subtitle2"
                  >
                    {t('LandingPagePageTitle')}
                  </Link>
                </Grid>
                <Grid item>
                  <Typography
                    color="text.primary"
                    sx={{ mb: 5 }}
                    variant="subtitle2"
                  >
                    {t('ApprovalsPageTitle')}

                  </Typography>
                </Grid>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Grid sx={{ minWidth: 700 }}>
            <Tabs
              indicatorColor="primary"
              onChange={handleTabsChange}
              scrollButtons="auto"
              textColor="primary"
              value={currentTab}
              variant="scrollable"
              sx={{
                mb: 2
              }}
            >
              {tabs.map((tab) => (
                <Tab
                  key={tab.value}
                  label={tab.label}
                  value={tab.value}
                />
              ))}
            </Tabs>
            <FormControl
              sx={{ marginRight: '25px', marginBottom: '25px' }}
            >
              <InputLabel htmlFor="multiselect-dropdown">Filter</InputLabel>
              <Select
                size="small"
                value={selectedFilter}
                onChange={changeFilter}
                label="Filter"
                inputProps={{
                  name: 'filter',
                  id: 'multiselect-dropdown',
                }}
              >
                <MenuItem value="all">{t('ApprovalsFilterAll')}</MenuItem>
                <MenuItem value="absence">{t('ApprovalsAbsenseTitle')}</MenuItem>
                <MenuItem value="attachment">{t('ApprovalsAttachmentTitle')}</MenuItem>
                <MenuItem value="holidayBonusChangeRequest">{t('ApprovalsHolidayBonusChangeRequest')}</MenuItem>
              </Select>
            </FormControl>
            {currentTab === 'Pending' && selectedFilter === 'holidayBonusChangeRequest' && (
              <>
                <ToggleButton
                  sx={{ marginRight: '10px' }}
                  size="small"
                  value="multiselect"
                  selected={multiselect}
                  onChange={() => { setMultiselect((prevState) => !prevState); }}
                >
                  {t('ApprovalsSelectMultiple')}
                </ToggleButton>
                {multiselect && (
                  <Button
                    sx={{ marginRight: '10px' }}
                    variant="outlined"
                    onClick={selectAll}
                  >
                    {t('ApprovalsSelectAll')}
                  </Button>
                )}
                {multiselect && (
                  <Button
                    variant="outlined"
                    onClick={approveSelected}
                  >
                    {t('ApprovalsApproveSelected')}
                  </Button>
                )}
              </>
            )}
            <ManagerSubstitutionAlert isLoading={isLoading} />
            {!isLoading && approvals.length === 0 && (
              <Box sx={{ mt: 15 }}>
                <Typography
                  variant="h5"
                  align="center"
                >
                  {t('ApprovalsNoApprovalsMessage')}
                </Typography>
              </Box>
            )}
            {!isLoading && approvals.length !== 0 && (
              <>
                {approvals.map((a, index) => (
                  renderApprovals(a, index)
                ))}
              </>
            )}
            {isLoading && (
              <Box
                display="flex"
                justifyContent="center"
                sx={{ mt: 10 }}
                bgcolor="background.secondary"
              >
                <CircularProgress
                  size={65}
                />
              </Box>
            )}
          </Grid>
        </Container>
      </Box>

    </>
  );
});

export default ApprovalsDeprecated;
