import { imageFileExtensions } from 'src/constants';

export const base64ImageToFile = async (base64Image: string, fileName: string): Promise<File> => {
  const mimeType = `image/${fileName.split('.').pop()}`;
  const res: Response = await fetch(base64Image);
  const blob: Blob = await res.blob();
  return new File([blob], fileName, { type: mimeType });
};

export const isImageFile = (fileName: string) => imageFileExtensions.includes('.' + fileName?.split('.').pop().toLowerCase());

export const isAllowedAttachmentFile = (fileName: string, allowedExtensions: string[]) => {
  return allowedExtensions.includes('.' + fileName?.split('.').pop().toLowerCase());
};

export const toBase64 = (file: File) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.toString());
    reader.onerror = (error) => reject(error);
  });

export const isImageSrcOK = (url: string) => {
  const image = new Image();
  image.src = url;
  return new Promise<boolean>((resolve) => {
    image.onload = () => resolve(true);
    image.onerror = () => resolve(false);
  });
};