import createSvgIcon from '@mui/material/utils/createSvgIcon';

// https://github.com/lipis/flag-icons

const FlagSe = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" id="flag-icons-se" viewBox="0 0 640 480">
  <path fill="#005293" d="M0 0h640v480H0z"/>
  <path fill="#fecb00" d="M176 0v192H0v96h176v192h96V288h368v-96H272V0z"/>
    </svg>,
    'FlagSe'
);

export default FlagSe;
