/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  $applyNodeReplacement,
  type DOMExportOutput,
  type EditorConfig,
  type LexicalNode,
  type NodeKey,
  type SerializedTextNode,
  type Spread,
  TextNode,
} from 'lexical';
import { InternalRole, UserRole } from 'src/types/apiSchemas';

export type SerializedMentionNode =
  Spread<{
    mentionName: string;
    userId: number;
    userRole: UserRole;
    internalRole: InternalRole;
  }, SerializedTextNode
  >;

export class MentionNode extends TextNode {
  __mention: string;
  __userId: number;
  __userRole: UserRole;
  __internalRole: InternalRole;

  static getType(): string {
    return 'mention';
  }

  static clone(node: MentionNode): MentionNode {
    return new MentionNode(node.__mention, node.__text, node.__key, node.__userId, node.__userRole, node.__internalRole);
  }
  static importJSON(serializedNode: SerializedMentionNode): MentionNode {
    const node = $createMentionNode(serializedNode.mentionName, serializedNode.userId, serializedNode.userRole, serializedNode.internalRole);
    node.setTextContent(serializedNode.text);
    node.setFormat(serializedNode.format);
    node.setDetail(serializedNode.detail);
    node.setMode(serializedNode.mode);
    node.setStyle(serializedNode.style);
    return node;
  }

  constructor(mentionName: string, text: string, key: NodeKey, userId: number, userRole: UserRole, internalRole: InternalRole) {
    super(text ?? mentionName, key);
    this.__mention = mentionName;
    this.__userId = userId;
    this.__userRole = userRole;
    this.__internalRole = internalRole;
  }

  exportJSON(): SerializedMentionNode {
    return {
      ...super.exportJSON(),
      mentionName: this.__mention,
      type: 'mention',
      version: 1,
      userId: this.__userId,
      userRole: this.__userRole,
      internalRole: this.__internalRole
    };
  }

  createDOM(config: EditorConfig): HTMLElement {
    const dom = super.createDOM(config);
    dom.className = 'mention';
    return dom;
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement('span');
    element.setAttribute('data-lexical-mention', 'true');
    element.textContent = this.__text;
    element.className = 'mention';
    return { element };
  }

  isTextEntity(): true {
    return true;
  }

  canInsertTextBefore(): boolean {
    return false;
  }

  canInsertTextAfter(): boolean {
    return false;
  }
}

export function $createMentionNode(mentionName: string, userId: number, userRole: UserRole, internalRole: InternalRole): MentionNode {
  const mentionNode = new MentionNode(mentionName, null, null, userId, userRole, internalRole);
  mentionNode.setMode('segmented').toggleDirectionless();
  return $applyNodeReplacement(mentionNode);
}

export interface Mention { userId: number, userRole: UserRole, internalRole: InternalRole }

export function $isMentionNode(
  node: LexicalNode | null | undefined,
): node is MentionNode {
  return node instanceof MentionNode;
}