import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AppBar, Box, Toolbar, IconButton, Typography, useMediaQuery } from '@mui/material';
import { experimentalStyled, } from '@mui/material/styles';
import type { AppBarProps, Theme } from '@mui/material';
import MenuIcon from '../../icons/Menu';
import AccountPopover from './AccountPopover';
import { isProduction } from 'src/utils/environmentUtils';
import DevInfoPopover, { PopoverLocation } from './DevInfoPopover';
import { useTranslation } from 'react-i18next';
import { useAppStore } from 'src/store/mobx/appStore';
import { observer } from 'mobx-react';
import CustomerSelection from './customerSelection/CustomerSelection';
import ThemeBasedLogo from '../common/ThemeBasedLogo';
import CustomerLinks from './CustomerLinks';
import { useTheme } from '@mui/material/styles';

interface DashboardNavbarProps extends AppBarProps {
  onSidebarMobileOpen?: () => void;
}

const DashboardNavbarRoot = experimentalStyled(AppBar)(
  ({ theme }) => (
    {
      ...(
        theme.palette.mode === 'light' && {
          backgroundColor: theme.palette.primary.navigationBackground,
          boxShadow: 'none',
          color: theme.palette.primary.contrastText,
          borderBottom: `1px solid ${theme.palette.divider}`,
        }
      ),
      ...(
        theme.palette.mode === 'dark' && {
          backgroundColor: theme.palette.primary.navigationBackground,
          borderBottom: `1px solid ${theme.palette.divider}`,
          boxShadow: 'none'
        }
      ),
      zIndex: theme.zIndex.drawer + 1
    }
  )
);

const DashboardNavbar: FC<DashboardNavbarProps> = observer((props) => {
  const { onSidebarMobileOpen, ...other } = props;
  const { t } = useTranslation();
  const appStore = useAppStore();
  const { user } = appStore.loginStore.get();
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const theme = useTheme();

  return (
    <DashboardNavbarRoot {...other}>
      <Toolbar sx={{ height: 82 }}>
        <IconButton
          color="inherit"
          onClick={onSidebarMobileOpen}
          sx={{
            display: {
              lg: 'none'
            },
            marginRight: '25px',
            color: '#fff'
          }}
          size="large"
        >
          <MenuIcon fontSize="small" />
        </IconButton>
        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', columnGap: 40, overflow: 'hidden' }}>
          <div style={{ ...(!lgUp ? { width: 120, paddingTop: 5 } : {}) }}>
            <RouterLink
              to="/dashboard/landingpage"
            >
              <ThemeBasedLogo />
            </RouterLink>
          </div>
          {!lgUp && (
            <Box sx={{
              display: 'flex',
              overflow: 'hidden'
            }}
            >
              <CustomerSelection
                padding={0}
                displayMenu
              >
                <Typography
                  component="span"
                  variant="h6"
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    color: '#fff'
                  }}
                >
                  {user.customerId === 0 ? t('AllCustomers') : user.customerName}
                </Typography>
              </CustomerSelection>
            </Box>
          )}
        </div>
        <Box
          sx={{
            flexGrow: 1,
          }}
        />
        {!mdDown && (
          <CustomerLinks linkColor={theme.palette.primary.integrataFeatherGrey} />
        )}
        {!isProduction
          && (
            <Box sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
            >
              <DevInfoPopover location={PopoverLocation.Navbar} />
            </Box>
          )}
        <Box sx={{
          ml: 2,
          display: 'flex',
          justifyContent: 'center'
        }}
        >
          <AccountPopover />
        </Box>
      </Toolbar>
    </DashboardNavbarRoot>
  );
});

DashboardNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func
};

export default DashboardNavbar;
